import React from 'react';

import './alertBox.scss';

interface Props {
  title: string;
  content: string;
  onClick?: () => void;
}

const AlertBox = ({ title, content, onClick }: Props): JSX.Element => (
  <div
    role="button"
    className={`alert-box-container ${onClick && 'is-button'}`}
    onClick={onClick}
    tabIndex={0}
  >
    <div className="alert-box">
      <div className="alert-box-title">{title}</div>
      <div className="alert-box-content">{content}</div>
    </div>
  </div>
);

AlertBox.defaultProps = {
  onClick: null,
};

export default AlertBox;
