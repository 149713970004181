import React, { useEffect, useRef, useState } from 'react';

import './style.scss';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

interface Props {
  placeholder: string;
  options: Array<{
    label: string;
    option: any;
  }>;
  value: any;
  onChange: (value: any) => void;
  name?: string;
  rule?: Record<string, unknown>;
  errors?: any;
  register?: any;
  error?: boolean;
  customStyle?: string;
  customOptionStyle?: string;
}

const dropdown = (
  {
    placeholder,
    options,
    value,
    onChange,
    name,
    rule,
    errors,
    register,
    error,
    customStyle,
    customOptionStyle,
  }: Props,
): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (register && rule && name) register({ name }, rule);
  }, [register]);

  useEffect(() => {
    const handleOutsideClick = (event: any): void => {
      if (ref.current && !ref.current?.contains(event.target)) {
        setOpened(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);

  const valueLabel = options.find((o) => o.option === value)?.label;

  const dropdownComponent = (): JSX.Element => (
    <div
      role="listbox"
      ref={ref}
      className={`dropdown-container ${customStyle} ${error && 'error'}`}
      onClick={(): void => setOpened(!opened)}
      tabIndex={0}
    >
      <button type="button" className={`dropdown-label ${valueLabel ? 'filled' : ''}`}>
        {valueLabel || placeholder}
      </button>
      {opened ? <ArrowUp title="up" className="dropdown-arrow" />
        : <ArrowDown title="down" className="dropdown-arrow" />}
      {opened && (
        <div className={`dropdown-options-container ${customOptionStyle}`}>
          <div className="dropdown-background" />
          <div className="dropdown-options">
            {options.map(({
              label,
              option,
            }) => (
              <div
                role="option"
                aria-selected={option === value}
                key={`Option${label}`}
                className="dropdown-option"
                onClick={(): void => {
                  onChange(option);
                  setOpened(false);
                }}
                tabIndex={0}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  return register ? (
    <>
      <input style={{ display: 'none' }} name={name} value={value} readOnly type="number" />
      <div className="dropdown-component">
        {dropdownComponent()}
        {errors && name && <span className="span-error">{errors[name]?.message}</span>}
      </div>
    </>
  )
    : dropdownComponent();
};

dropdown.defaultProps = {
  name: '',
  rule: null,
  errors: null,
  register: null,
  error: false,
  customStyle: '',
  customOptionStyle: '',
};
export default dropdown;
