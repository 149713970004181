import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { auth } from '../../api/api';

export default (): JSX.Element => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  const {
    register, handleSubmit, setValue, errors,
  } = useForm();

  useEffect(() => {
    register({ name: 'username' }, { required: 'Ce champ est requis' });
    register({ name: 'password' }, { required: 'Ce champ est requis' });
    // eslint-disable-next-line
    }, [])

  const submit = (): void => {
    auth(username,
      password,
      () => setRedirect(true),
      () => null);
  };

  return (
    <div className="Login">
      { redirect && <Redirect to="/reservation/seller" />}
      <form onSubmit={handleSubmit(submit)}>
        <label htmlFor="usernameInput">
          {' '}
          Username :
          <input
            type="text"
            value={username}
            name="username"
            onChange={(e): void => {
              setValue('username', e.target.value);
              setUsername(e.target.value);
            }}
          />
        </label>
        <span className="span-error">{errors.username?.message}</span>
        <br />
        <label htmlFor="passwordInput">
          {' '}
          Password :
          <input
            id="passwordInput"
            type="text"
            value={password}
            name="password"
            onChange={(e): void => {
              setValue('password', e.target.value);
              setPassword(e.target.value);
            }}
          />
        </label>
        <span className="span-error">{errors.password?.message}</span>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
