import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import './style.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  Button, LessonRecapBlock, LessonRecapTotal, SkiSet, Title,
} from '..';
import {
  addLesson,
  removeLesson,
  selectLessons,
  updateLesson,
} from '../../services/lessons/lessonsSlice';
import { newLesson } from '../../helpers/lesson';
import {
  selectSelectedStation,
  setReservationFocus,
} from '../../services/reservation/reservationSlice';
import { Lesson } from '../../types/lesson';

export default (): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const selectedStation = useAppSelector(selectSelectedStation);
  const [redirect, setRedirect] = useState<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (scrollRef.current) {
      window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop });
    }
  }, []);

  const onDelete = useCallback((lesson: Lesson): void => {
    dispatch(removeLesson(lesson));
  }, [dispatch]);

  const onAdd = useCallback((): void => {
    dispatch(addLesson(newLesson(lessons.length)));
    setRedirect('/reservation/lessons');
  }, [dispatch]);

  const onChange = useCallback((data: Partial<Lesson>): void => {
    dispatch(updateLesson(data));
  }, [dispatch]);

  const onRedirect = useCallback((index: number | null, target: string): void => {
    dispatch(setReservationFocus(index));
    setRedirect(target);
  }, [dispatch, setRedirect]);

  return (
    <>
      { redirect && <Redirect to={redirect} />}
      <div className="progress-bar-100" />
      <div ref={scrollRef} className="lesson-recap">
        <Title icon="✏" label={`Récapitulatif de votre séjour à ${selectedStation.name}`} />
        <div className="lesson-recap-container">
          {isMobile
                    && <LessonRecapTotal next={(): void => setRedirect('/reservation/payment')} />}
          <div className="lesson-recap-detail">
            {lessons.map((lesson) => (
              <LessonRecapBlock
                key={`LessonRecap${lesson.id}`}
                lesson={lesson}
                onClick={(i: number, target: string): void => onRedirect(i, target)}
                onChange={(data: Partial<Lesson>): void => onChange(data)}
                onDelete={(data:Lesson): void => onDelete(data)}
              />
            ))}
            <Button className="white-button" label="Ajouter un cours" onClick={(): void => onAdd()} />
            <SkiSet />
          </div>
          {!isMobile
                    && <LessonRecapTotal next={(): void => setRedirect('/reservation/payment')} />}
        </div>
      </div>
    </>
  );
};
