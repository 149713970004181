import React, { useState } from 'react';

import '../paymentForm/paymentForm.scss';
import { Button, Input } from '../index';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser, updateUser } from '../../services/users/userSlice';
import { auth, get } from '../../api/api';

interface Props {
  hasBeenSubmit: boolean;
}

export default ({ hasBeenSubmit }: Props): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const authUser = () => {
    if (!user.email || !user.password) {
      return;
    }
    auth(user.email, user.password,
      () => {
        setErrorMessage('');
        get('/me/', (res) => {
          dispatch(updateUser({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            isLoggedIn: true,
          }));
        }, () => {});
      },
      () => {
        setErrorMessage('Identifiant ou mot de passe incorrect');
      });
  };

  return (
    <>
      <div className="tunnel-informations-line">
        <Input
          type="text"
          placeholder="Votre adresse email"
          value={user.email}
          onChange={(email) => dispatch(updateUser({ email }))}
          extraClass={(hasBeenSubmit && !user.email)
            ? 'payment-form-container-error' : ''}
        />

        <Input
          type="password"
          placeholder="Votre mot de passe"
          value={user.password}
          onChange={(password) => dispatch(updateUser({ password }))}
          extraClass={(hasBeenSubmit && !user.password)
            ? 'payment-form-container-error' : ''}
        />
      </div>
      <Button
        label="Connection"
        className="white-button"
        onClick={authUser}
      />
      <br />
      <span className="error-message-tunnel-info">{errorMessage}</span>
    </>
  );
};
