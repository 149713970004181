import moment from 'moment/moment';
import React from 'react';
import { useAppSelector } from '../../hooks';
import { Button } from '..';
import './lessonRecapTotal.scss';
import { getLevelLabel } from '../../helpers/lesson';
import { ReactComponent as Discount } from '../../assets/icons/discount.svg';
import { selectLessons } from '../../services/lessons/lessonsSlice';
import {
  selectArrivalDate, selectDepartureDate, selectSelectedStation,
} from '../../services/reservation/reservationSlice';

interface Props {
  next?: () => void;
}

const LessonRecapTotal = ({ next }: Props): JSX.Element => {
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const selectedStation = useAppSelector(selectSelectedStation);
  const lessons = useAppSelector(selectLessons);

  return (
    <div className="lesson-recap-total">
      <div className="lesson-recap-total-container">
        <div className="lesson-recap-total-block">
          <div className="lesson-recap-total-line row">
            <div className="lesson-recap-total-title">Station</div>
            <div className="lesson-recap-total-text">{selectedStation?.name}</div>
          </div>
          <div className="lesson-recap-total-line row">
            <div className="lesson-recap-total-title">Date</div>
            <div className="lesson-recap-total-text">
              {moment(dateFrom).format('DD/MM/YYYY')}
              {' '}
              -
              {' '}
              {moment(dateTo).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="lesson-recap-total-line col">
            <div className="lesson-recap-total-title">Cours</div>
            <div>
              {lessons.map((lesson) => (
                <div key={`LessonRecapTotal${lesson.id}`} className="lesson-recap-total-subline">
                  <div className="lesson-recap-total-secondarytext">
                    <span>{lesson.isPrivate ? 'cours privé' : `cours co. ${getLevelLabel(lesson)}`}</span>
                    <span>x1</span>
                  </div>
                  <div className="lesson-recap-total-text">
                    {lesson.price}
                    €
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="lesson-recap-total-line col">
            <div className="lesson-recap-total-title">Options</div>
            <div>
              {lessons.map((lesson) => lesson.pass && (
              <div key={`LessonRecapTotalLine${lesson.id}`} className="lesson-recap-total-subline">
                <div className="lesson-recap-total-secondarytext">
                  <span>Forfait</span>
                  <span>x1</span>
                </div>
                <div className="lesson-recap-total-text">217€</div>
              </div>
              ))}
            </div>
          </div>
          <div className="lesson-recap-total-line col">
            <div className="lesson-recap-total-title">Code de réduction</div>
            <form className="lesson-recap-total-discount">
              <input type="text" placeholder="Ajouter un code" />
              <Discount title="discount" />
            </form>
          </div>
          <div className="lesson-recap-total-line row">
            <div className="lesson-recap-total-title">Total</div>
            <div className="lesson-recap-total-text bigger">
              {lessons.reduce(
                (previousValue, currentValue) => previousValue + currentValue.price, 0,
              )}
              €
            </div>
          </div>
        </div>
        {next && <Button label="Procéder au paiement" className="validation-button" onClick={(): void => next()} />}
      </div>
    </div>
  );
};

LessonRecapTotal.defaultProps = {
  next: null,
};
export default LessonRecapTotal;
