import { Moment } from 'moment/moment';
import {
  auth, get, post, refresh,
} from './api';
import { Station } from '../types/station';

export const getToken = (
  username: string,
  password: string,
  onSuccess: (tokens: any) => void,
  onFail: (err: any) => void,
): void => {
  refresh(
    () => onSuccess,
    () => auth(
      username,
      password,
      (res: any) => onSuccess(res),
      (err: any): void => onFail(err),
    ),
  );
};

export const getTunnelInfo = (
  data: {
    station:Station;
    date:{
      arrival:Moment;
      departure:Moment;
    }
  },
  onSuccess: (res: any) => void,
  onFail: (err: any) => void,
): void => {
  post(
    '/tunnel-info/',
    data,
    (res: any) => onSuccess(res),
    (err: any) => onFail(err),
  );
};

// Adds a product to oscar basket
// data = {url: 'product_url', quantity: number}
// more info here https://stage.ski-family.fr/api/api-docs/redoc/#operation/api_basket_add-product_create
export const addToBasket = (
  data: any,
  onSuccess: (res: any) => void,
  onFail: (err: any) => void,
): void => {
  post(
    '/basket/add-product/',
    data,
    (res: any) => onSuccess(res),
    (err: any) => onFail(err),
  );
};

// Gets the current basket (with many intel like total price, discounts...)
export const getBasket = (onSuccess: (res: any) => void, onFail: (err: any) => void): void => {
  get('/basket/',
    (res: any) => onSuccess(res),
    (err: any) => onFail(err));
};

// prepare for checkout
// data = {basket: 'basket_url', shipping_adress}
// more info here https://stage.ski-family.fr/api/api-docs/redoc/#operation/api_checkout_create
export const prepareCheckout = (
  data: any,
  onSuccess: (res: any) => void,
  onFail: (err: any) => void,
): void => {
  post(
    '/api/checkout/',
    data,
    (res: any) => onSuccess(res),
    (err: any) => onFail(err),
  );
};
