import React from 'react';

const NoAvailability = ():JSX.Element => (
  <div className="step3-no-availability-container">
    <h1 className="no-availability-header">
      <strong>Désolé! </strong>
      la vente en ligne est actuellement
      {' '}
      <br />
      {' '}
      fermée pour ces dates...😷
    </h1>
    <p className="contact-us-text">
      Contactez nous pour connaitre toutes nos
      disponibilités
    </p>
    <div className="contact-link-wrapper">
      <a
        href="tel:+33 4 81 68 09 06"
        className="skifamily-button primary-button phone-link"
      >
        +33 (0)4 81 68 09
        06
      </a>
      <a
        href="mailto:contact@ski-family.fr"
        className="skifamily-button white-button"
      >
        contact@ski-family.fr
      </a>
    </div>
    <div className="contact-pro">
      Vous etes un pro ?
      <br />
      <a href="mailto:contact@ski-family.fr"> Demandez une démo !</a>
    </div>
  </div>
);

export default NoAvailability;
