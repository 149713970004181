import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {
  Step1Station, Step2Dates, Step3Lessons, Step4Details, Step5Recap, Step6Payment,
  Step7Validation, SellerInterface, Login, TunnelPreFilling,
} from '../scenes';

export default (): JSX.Element => (
  <Router>
    <Switch>
      <Route exact path="/reservation/" component={TunnelPreFilling} />
      <Route exact path="/reservation/station" component={Step1Station} />
      <Route exact path="/reservation/dates" component={Step2Dates} />
      <Route exact path="/reservation/lessons" component={Step3Lessons} />
      <Route exact path="/reservation/details" component={Step4Details} />
      <Route exact path="/reservation/recap" component={Step5Recap} />
      <Route exact path="/reservation/payment" component={Step6Payment} />
      <Route exact path="/reservation/payment-confirmation" component={Step7Validation} />
      <Route exact path="/reservation/seller" component={SellerInterface} />
      <Route exact path="/reservation/login" component={Login} />
    </Switch>
  </Router>
);
