import React, { useState, useRef, useEffect } from 'react';
import { privacyDropdownOptions } from '../../constants/tunnelOptions';
import './style.scss';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { Lesson } from '../../types/lesson';

interface Props {
  placeholder: string;
  selected: Lesson;
  isEmpty: boolean;
  onChange: (value: any) => void;
  error: boolean;
}

export default ({
  placeholder, selected, isEmpty, onChange, error,
}: Props): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: any): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpened(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);

  const valueLabel = privacyDropdownOptions.find((o) => o.value === selected.isPrivate)?.label;

  return (
    <div role="listbox" tabIndex={0} ref={ref} className={`lesson-dropdown ${error && 'error'}`} onClick={(): void => setOpened(!opened)}>
      <button type="button" className={`lesson-dropdown-label ${!isEmpty ? 'filled' : ''}`}>
        { valueLabel || placeholder }
      </button>
      {opened ? <ArrowUp title="up" className="lesson-dropdown-arrow" />
        : <ArrowDown title="down" className="lesson-dropdown-arrow" />}
      { opened && (
        <div className="lesson-dropdown-options-container">
          <div className="lesson-dropdown-background" />
          <div className="lesson-dropdown-options">
            { privacyDropdownOptions.map(({
              title, label, value, description,
            }) => (
              <div
                role="option"
                aria-selected={value === selected.isPrivate}
                tabIndex={0}
                key={`LessonDropdownOption${label}`}
                className="lesson-dropdown-option"
                onClick={(): void => {
                  onChange(value);
                  setOpened(false);
                }}
              >
                <div className={`lesson-dropdown-option-label ${value === selected.isPrivate ? 'selected' : ''}`}>
                  {title}
                </div>
                <div className="lesson-dropdown-option-descriptions">
                  {description.map((d) => (
                    <div key={`${d}`}>
                      <span className="lesson-dropdown-option-tic" />
                      {d}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
