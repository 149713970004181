import React from 'react';
import Button from '../button/button';
import './style.scss';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';

interface Props {
  buttons: Array<{
    label: string;
    className: string;
    onClick: () => void;
    disabled?: boolean;
  }>;
  error?: string;
}

const TunnelFooter = ({ buttons, error }: Props): JSX.Element => (
  <div className="footer">
    {error && (
    <div className="footer-error">
      <Warning title="Erreur" />
      {error}
    </div>
    )}
    <div className="footer-buttons">
      {buttons.map((b) => (
        <Button
          className={b.className}
          disabled={b.disabled}
          label={b.label}
          onClick={b.onClick}
          key={`TunnelFooterButton${b.label}`}
        />
      ))}
    </div>
  </div>
);

TunnelFooter.defaultProps = {
  error: '',
};

export default TunnelFooter;
