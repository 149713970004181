import React, { useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { findIndex } from 'lodash';

import { useAppDispatch, useAppSelector } from '../../hooks';

import {
  AvailabilityLoading, Footer, LessonSelection, NoAvailability,
} from '../../components';
import './style.scss';
import { Lesson } from '../../types/lesson';
import {
  selectReservationFocus,
  setReservationFocus,
} from '../../services/reservation/reservationSlice';
import { selectLessons } from '../../services/lessons/lessonsSlice';
import { selectProducts } from '../../services/products/productsSlice';

export default (): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const products = useAppSelector(selectProducts);
  const focus = useAppSelector(selectReservationFocus);
  const [error, setError] = useState('');
  const [redirection, setRedirect] = useState('');
  const dispatch = useAppDispatch();

  const getFirstLineNotCompleted = useMemo(() => findIndex(
    lessons,
    (l: Lesson) => (l.isPrivate ? !l.nbPeople : !l.age),
  ), [lessons]);

  const prevStep = useCallback(() => {
    dispatch(setReservationFocus(null));
    setRedirect('/reservation/dates');
  }, [dispatch, setRedirect]);

  const nextStep = useCallback(() => {
    if (getFirstLineNotCompleted >= 0 || lessons.length === 0) {
      setError('Il nous manque encore quelques infos pour passer à l’étape suivante');
      dispatch(setReservationFocus(getFirstLineNotCompleted));
    } else {
      dispatch(setReservationFocus(null));
      setRedirect('/reservation/details');
    }
  }, [getFirstLineNotCompleted, lessons, setError, dispatch, setRedirect]);

  const displayScene = (): JSX.Element => {
    if (products === null) {
      return (
        <div className="step3-loader-container">
          <AvailabilityLoading />
        </div>
      );
    }
    if (products.length === 0) {
      return (
        <NoAvailability />
      );
    }
    return (
      <>
        <LessonSelection
          error={error}
          focus={focus}
        />
        <Footer
          buttons={[
            {
              label: 'Précédent',
              className: 'cancel-button',
              onClick: (): void => prevStep(),
              disabled: false,
            },
            {
              label: 'Etape suivante',
              className: (getFirstLineNotCompleted >= 0 || lessons.length === 0) && error ? 'error-button' : 'primary-button',
              onClick: (): void => nextStep(),
            },
          ]}
          error={getFirstLineNotCompleted === -1 || lessons.length === 0 ? '' : error}
        />
      </>
    );
  };

  return (
    <>
      {redirection && <Redirect to={redirection} />}
      <div className="progress-bar-60" />
      {displayScene()}
    </>
  );
};
