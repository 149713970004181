import { find } from 'lodash';
import { Lesson } from '../types/lesson';
import { levels } from '../constants/tunnelOptions';
import { ADULTS, SKI } from '../constants/constants';

export const newLesson = (id:number): Lesson => ({
  id,
  isPrivate: false,
  activity: SKI,
  age: '',
  nbPeople: 0,
  timeslot: null,
  nbDays: null,
  startDay: null,
  level: 0,
  nbLessons: 0,
  classLength: 0,
  lessonDays: [],
  pass: false,
  price: 0,
});

export const getLevelLabel = (lesson: Lesson): string => find(levels, (l) => l.value === lesson.level)?.label || '';

export const getAgeLabel = (lesson: Lesson): string => (lesson.age === ADULTS ? 'Adultes' : 'Enfants');
