import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { BeatLoader } from 'react-spinners';
import React, { useState } from 'react';
import { inputInnerStyle, inputInnerStyleMissing } from '../paymentForm/StripeInputsStyle';
import {
  Button, Checkbox, Input, Title,
} from '../index';
import { post } from '../../api/api';
import { selectUser } from '../../services/users/userSlice';
import { useAppSelector } from '../../hooks';
import { selectClientSecret, selectOrderId } from '../../services/clientSecret/clientSecretSlice';

interface Props {
  hasBeenSubmit:boolean;
  setHasBeenSubmit:(submited:boolean)=>void;
  waitingForValidation:boolean;
  setWaitingForValidation:(waiting:boolean)=>void;
  setRedirect: (url: string) => void;
  setPaymentError: (error: string)=>void;
}

const Checkout = ({
  hasBeenSubmit, setHasBeenSubmit, waitingForValidation,
  setWaitingForValidation, setRedirect, setPaymentError,
}:Props):JSX.Element => {
  const [cardName, setCardName] = useState('');
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [acceptCGU, setAcceptCGU] = useState(false);
  const user = useAppSelector(selectUser);
  const orderId = useAppSelector(selectOrderId);
  const clientSecret = useAppSelector(selectClientSecret);
  const stripe = useStripe();
  const elements = useElements();

  const payment = async () => {
    if (!elements || !stripe) return;

    const numberElement = elements.getElement(CardNumberElement);
    // CVC and expiry automatically detected
    setWaitingForValidation(true);
    setHasBeenSubmit(true);
    if (numberElement) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: numberElement,
          // billing details options can be found here : https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details
          billing_details: {
            name: `${user.lastName} ${user.firstName}`,
            phone: user.phone,
            email: user.email,
          },
        },
      });
      if (result.error) {
        setWaitingForValidation(false);
        // TODO : ADD A MESSAGE PER ERROR CODE, ERROR CODES CAN BE FOUND HERE : https://stripe.com/docs/testing#cards-responses
        setPaymentError('Paiement refusé');
        post(
          'api/order/status/update/',
          {
            orderId,
            success: false,
          },
          () => null,
          () => null,
        );
      } else if (result && result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        post(
          'api/order/status/update/',
          {
            orderId,
            success: true,
          },
          () => {},
          () => null,
        );

        setRedirect('/reservation/payment-confirmation');

      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      }
    } else {
      setWaitingForValidation(false);
    }
  };

  const onSubmit = async (ev: any) => {
    ev.preventDefault();
    payment();
  };

  return (
    <>
      <Title icon="🔒" label="Votre moyen de paiement" />
      <div className="tunnel-informations-text">
        Paiement sécurisé par carte de crédit ou de débit
      </div>
      <div className="tunnel-informations-line">
        <Input
          type="text"
          placeholder="Nom du porteur de carte"
          value={cardName}
          onChange={setCardName}
          extraClass={(hasBeenSubmit && !cardName)
            ? 'payment-form-container-error' : ''}
        />
        <div className={(hasBeenSubmit && !cardNumberValid)
          ? 'payment-form-input-container-invalid' : 'payment-form-input-container'}
        >
          <CardNumberElement
            options={(hasBeenSubmit && !cardNumberValid) ? inputInnerStyleMissing : inputInnerStyle}
            onChange={(element) => setCardNumberValid(!element.empty && element.complete)}
          />
        </div>
      </div>
      <div className="tunnel-informations-line">
        <div className={(hasBeenSubmit && !cardExpiryValid)
          ? 'payment-form-input-container-invalid' : 'payment-form-input-container'}
        >
          <CardExpiryElement
            options={(hasBeenSubmit && !cardExpiryValid) ? inputInnerStyleMissing : inputInnerStyle}
            onChange={(element) => setCardExpiryValid(!element.empty && element.complete)}
          />
        </div>
        <div className={(hasBeenSubmit && !cardCvcValid)
          ? 'payment-form-input-container-invalid' : 'payment-form-input-container'}
        >
          <CardCvcElement
            options={(hasBeenSubmit && !cardCvcValid) ? inputInnerStyleMissing : inputInnerStyle}
            onChange={(element) => setCardCvcValid(!element.empty && element.complete)}
          />
        </div>
      </div>
      <div className="tunnel-informations-cguline">
        <Checkbox
          value={acceptCGU}
          onClick={setAcceptCGU}
          missing={hasBeenSubmit && !acceptCGU}
        >
          {
                    (hasBeenSubmit && !acceptCGU)
                      ? (
                        <span className="tunnel-informations-cgu-missing">
                          J&apos;accepte les conditions générales de ventes
                        </span>
                      )
                      : (
                        <span className="tunnel-informations-cgu">
                          J&apos;accepte les conditions générales de ventes
                        </span>
                      )
                }
        </Checkbox>
      </div>
      <div className="tunnel-informations-left-buttons">
        <Button
          className="white-button"
          label="Modifier mon panier"
          onClick={(): void => setRedirect('/reservation/recap')}
        />
        {waitingForValidation
          ? (
            <BeatLoader
              css="margin: auto 15px;"
              color="#5A3FDC"
              size={15}
              loading
            />
          )
          : (
            <Button
              label="Valider mon paiement"
              className="validation-button"
              onClick={onSubmit}
              disabled={!stripe}
            />
          )}
      </div>
    </>
  );
};

export default Checkout;
