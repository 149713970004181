import React from 'react';

import './skiSet.scss';
import { ReactComponent as SkiSetLogo } from '../../assets/images/skiset.svg';

import { Button } from '..';

interface Props {
  onClick?: () => void;
  title?: string;
}

const SkiSet = ({ onClick, title }: Props): JSX.Element => (
  <div className="ski-set">
    <h2 className="ski-set-title">{title}</h2>
    <div className="ski-set-box">
      <div className="ski-set-box-left">
        <div className="ski-set-box-title">
          Location de matériel - jusqu’à -50%
        </div>
        <div className="ski-set-box-text">
          En réservant vos cours avec Ski Family, obtenez des réductions suplémentaires chez notre
          partenaire.
          Pour en bénéficier, suivez les étapes en fin de réservation.
        </div>
      </div>
      <div className="ski-set-view-offer-container">
        <SkiSetLogo title="logo skiset" />
        {onClick && <Button onClick={onClick} className="" disabled={false} label="Voir l'offre" />}
      </div>
    </div>
  </div>
);

SkiSet.defaultProps = {
  onClick: null,
  title: '',
};
export default SkiSet;
