export const MORNING = 'morning';
export const NOON = 'noon';
export const AFTERNOON = 'afternoon';
export const ALLDAY = 'all-day';
export const TIME_FORMAT = 'HH:mm:ss';
export const HOURS = 'hours';
export const PRIVATE_LESSON = 'private-lesson';
export const NUMBER_OF_HOURS = 'number_of_hours';
export const TYPE = 'type';
export const TIMESLOT = 'timeslot';
export const ACTIVITY = 'activity';
export const AGE = 'age';
export const SUNDAY = 'sunday';
export const MONDAY = 'monday';
export const SKI = 'Skiing';
export const SNOWBOARD = 'Snowboard';
export const SNOWSHOEING = 'Snowshoes';
export const ALL_AGES = 'ALL';
export const ADULTS = 'ADULTS';
export const CHILDREN = 'CHILDREN';
