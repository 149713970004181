import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { DateSelection, Footer } from '../../components';
import { setProducts } from '../../services/products/productsSlice';
import { getTunnelInfo } from '../../api';

import './style.scss';
import {
  selectArrivalDate,
  selectDepartureDate,
  selectSelectedStation,
} from '../../services/reservation/reservationSlice';

export default (): JSX.Element => {
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const selectedStation = useAppSelector(selectSelectedStation);
  const [redirection, setRedirect] = useState<string>('');
  const dispatch = useAppDispatch();

  const nextStep = useCallback(() => {
    if (dateFrom && dateTo) {
      getTunnelInfo(
        {
          station: selectedStation,
          date: {
            arrival: dateFrom.subtract(1, 'days'),
            departure: dateTo.add(1, 'days'),
          },
        },
        (res) => { dispatch(setProducts(res.data.products)); },
        () => dispatch(setProducts([])),
      );
      setRedirect('/reservation/lessons');
    }
  }, [setRedirect, selectedStation, dateFrom, dateTo, dispatch]);

  return (
    <>
      <div className="progress-bar-40" />
      <div className="scene-dates">
        {redirection && <Redirect to={redirection} />}
        <DateSelection />
      </div>
      <Footer buttons={[
        {
          label: 'Précédent', className: 'cancel-button', onClick: (): void => setRedirect('/reservation/station'), disabled: false,
        },
        {
          label: 'Etape suivante', className: dateFrom && dateTo ? 'primary-button' : 'disabled-button  ', onClick: (): void => nextStep(), disabled: !dateFrom || !dateTo,
        },
      ]}
      />
    </>
  );
};
