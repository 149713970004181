export const inputInnerStyle = {
  style: {
    base: {
      color: '#5A3FDC',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      fontStyle: 'normal',
      '::placeholder': {
        color: '#CAC2F5',
      },
    },
    invalid: {
      color: '#FF5800',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      fontStyle: 'normal',
    },
  },
};

export const inputInnerStyleMissing = {
  style: {
    base: {
      color: '#FF5800',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      fontStyle: 'normal',
    },
    empty: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      fontStyle: 'normal',
      '::placeholder': {
        color: '#FBC9B3',
      },
    },
  },
};
