import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Redirect } from 'react-router-dom';
import { findIndex } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { Footer, LessonDetails } from '../../components';
import './style.scss';
import { Lesson } from '../../types/lesson';
import { setReservationFocus } from '../../services/reservation/reservationSlice';
import { selectLessons } from '../../services/lessons/lessonsSlice';

export default (): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const [redirection, setRedirect] = useState('');
  const [error, setError] = useState('');
  const [validation, setValidation] = useState(false);
  const dispatch = useAppDispatch();

  // eslint-disable-next-line
    useEffect(() => {
    setError('');
  }, [lessons]);

  const getFirstLineNotCompleted = useMemo(() => findIndex(lessons, (l: Lesson) => (l.isPrivate
    ? l.nbLessons !== l.lessonDays.length || l.nbLessons === 0
    : l.startDay === null || l.timeslot === null)), [lessons]);

  const prevStep = useCallback((): void => {
    dispatch(setReservationFocus(null));
    setRedirect('/reservation/lessons');
  }, [dispatch, setRedirect]);

  const nextStep = useCallback((): void => {
    if (getFirstLineNotCompleted >= 0 || lessons.length === 0) {
      setError('Il nous manque encore quelques infos pour passer à l’étape suivante');
      setValidation(true);
      dispatch(setReservationFocus(getFirstLineNotCompleted));
    } else {
      dispatch(setReservationFocus(null));
      setRedirect('/reservation/recap');
    }
  }, [setRedirect, getFirstLineNotCompleted, lessons, dispatch]);

  return (
    <>
      {redirection && <Redirect to={redirection} />}
      <div className="progress-bar-80" />
      <LessonDetails validation={validation} />
      <Footer
        buttons={[
          {
            label: 'Précédent', className: 'cancel-button', onClick: (): void => prevStep(), disabled: false,
          },
          {
            label: 'Récapitulatif',
            className: ((getFirstLineNotCompleted >= 0 || lessons.length === 0) && error) ? 'error-button' : 'primary-button',
            onClick: (): void => nextStep(),
          },
        ]}
        error={getFirstLineNotCompleted === -1 || lessons.length === 0 ? '' : error}
      />
    </>
  );
};
