import moment from 'moment';
import { Product } from '../types/product';
import { Instructor } from '../types/instructor';
import {
  ACTIVITY, AGE,
  NUMBER_OF_HOURS, PRIVATE_LESSON, TIMESLOT, TYPE,
} from '../constants/constants';

export const isProductPrivateLesson = (product:Product): boolean => {
  const typeAttribute = product.attributes.find((attribute) => attribute.code === TYPE);
  if (typeAttribute) return typeAttribute.value === PRIVATE_LESSON;
  return false;
};

export const getProductAttribute = (product:Product, attributeCode:string):string => {
  const attribute = product.attributes.find((a) => a.code === attributeCode);
  return attribute ? attribute.value : '';
};

export const getProductClassLength = (product:Product):number => {
  const classLength = product.attributes.find((attribute) => attribute.code === NUMBER_OF_HOURS);
  return moment.duration(classLength?.value).hours();
};

export const getProductTimeSlot = (product:Product):string => {
  const timeslot = product.attributes.find((a) => a.code === TIMESLOT);
  return timeslot ? timeslot.value : '';
};

export const getProductActivity = (product:Product):string => {
  const activity = product.attributes.find((a) => a.code === ACTIVITY);
  return activity ? activity.value : '';
};

export const getProductAge = (product:Product):string => {
  const age = product.attributes.find((a) => a.code === AGE);
  return age ? age.value : '';
};

export const isProductStartOnSunday = (/* product:Product */):boolean => true;

export const isProductOfClassLength = (
  product: Product,
  value: number,
):Boolean => value === getProductClassLength(product);

export const getUniqueInstructorListFromProducts = (
  products: Array<Product>,
):Array<Instructor> => Array.from(
  new Set(products.map((product) => product.instructor && product.instructor.id)),
)
  .map((id) => {
    const found = products.find((p) => p.instructor && p.instructor.id === id);
    if (found != null) return found.instructor;
    return null;
  })
  .filter((i) => i !== null) as Array<Instructor>;
