import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment/moment';
import { Reservation } from '../../types/reservation';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';
import { Station } from '../../types/station';

/* eslint-disable no-param-reassign */

const initialState: Reservation = {
  selectedStation: {
    id: 0, description: '', closingHour: '', openingHour: '', lessonTimes: [], subLocations: [], name: '', slug: '',
  },
  dateFrom: null,
  dateTo: null,
  focus: null,
};
export const reservationSlice = createSlice(
  {
    name: 'reservation',
    initialState,
    reducers: {
      setReservationSelectedStation: (state, action:PayloadAction<Station>) => {
        state.selectedStation = action.payload;
      },
      setReservationDateFrom: (state, action:PayloadAction<string>) => {
        state.dateFrom = action.payload;
      },
      setReservationDateTo: (state, action:PayloadAction<string>) => {
        state.dateTo = action.payload;
      },
      setReservationFocus: (state, action:PayloadAction<number|null>) => {
        state.focus = action.payload;
      },
    },
  },
);

export const {
  setReservationSelectedStation, setReservationDateFrom, setReservationDateTo, setReservationFocus,
} = reservationSlice.actions;
export const selectReservation = (state:RootState) => state.reservation;
export const selectSelectedStation = (state:RootState) => state.reservation.selectedStation;
export const selectArrivalDate = (state:RootState):null | moment.Moment => (
  state.reservation.dateFrom === null ? null : moment(state.reservation.dateFrom)
);
export const selectDepartureDate = (state:RootState):null | moment.Moment => (
  state.reservation.dateTo === null ? null : moment(state.reservation.dateTo)
);
export const selectReservationFocus = (state:RootState) => state.reservation.focus;

export default reservationSlice.reducer;
