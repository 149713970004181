import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppSelector } from '../../hooks';

import {
  AlertBox, Button, OrderDetails, ProductDetails, SkiSet, StudentsAndReferentsForm, Title,
} from '../../components';
import './style.scss';
import '../../components/input/input.scss';
import { get, post } from '../../api/api';
import { Lesson } from '../../types/lesson';
import { selectLessons } from '../../services/lessons/lessonsSlice';
import { selectReservation } from '../../services/reservation/reservationSlice';
import { selectUser } from '../../services/users/userSlice';

export default (): JSX.Element => {
  const userSelector = useAppSelector(selectUser);
  const lessons = useAppSelector(selectLessons);
  const reservationSelector = useAppSelector(selectReservation);

  const [genders, setGenders] = useState<Array<{ id: number; name: string }>>([]);
  const [languages, setLanguages] = useState<Array<{ id: number; name: string }>>([]);

  const formMethods = useForm();

  const topRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (topRef.current) {
      window.scrollTo({ behavior: 'smooth', top: topRef.current.offsetTop });
    }
  }, []);

  useEffect((): void => {
    get('/genders/',
      (res) => {
        setGenders(res.data);
      },
      () => null);
    get('/languages/',
      (res) => {
        setLanguages(res.data);
      },
      () => null);
  }, [reservationSelector]);

  const submitData = (): void => {
    post('/students-and-referents/create/',
      {
        user_id: userSelector.id,
        students: userSelector.students,
        referents: userSelector.referents,
      },
      () => null,
      () => null);
  };

  const studentsAndReferentsForms = (): Array<JSX.Element> => lessons.map(
    (value: Lesson, index: number) => (
      <StudentsAndReferentsForm
        key={`StudentAndReferentForm${value.id}`}
        id={index}
        genders={genders}
        languages={languages}
      />
    ),
  );

  const moveToForm = (): void => {
    if (formRef.current) {
      window.scrollTo({ behavior: 'smooth', top: formRef.current.offsetTop });
    }
  };

  return (
    <div className="scene-validation" ref={topRef}>
      <Title label={`Merci ${userSelector.firstName} !!`} icon="⛷" />
      <div className="scene-validation-subtitle">Un email de confirmation vient de vous être envoyé.</div>
      <AlertBox title="Important" content="👇 Dernière étape avant de dévaler les pistes 👇" onClick={moveToForm} />
      <div className="scene-validation-instruction-container">
        <h2 className="scene-validation-instruction-title">Informez vos moniteurs</h2>
        <p className="scene-validation-instruction-content">
          Pour des cours de ski personalisés,
          transmettez aux moniteurs les informations importantes sur leurs élèves !
        </p>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitData)} ref={formRef}>
          {studentsAndReferentsForms()}
          <Button disabled={false} label="Envoyer" className="scene-validation-button" onClick={submitData} />
        </form>
      </FormProvider>
      <hr />
      <SkiSet
        onClick={(): Window | null => window.open('https://www.skiset.com/', '_blank')}
        title="Pour résever vos ski, c'est par ici ! "
      />
      <hr />
      <OrderDetails
        title="Détails de la commande"
        orderId={116416}
        orderDate={(new Date()).toDateString()}
        customerEmail={userSelector.email}
        orderTotal={lessons !== null ? lessons.reduce(
          (previousValue, currentValue) => previousValue + currentValue.price, 0,
        ) : 0}
        orderPaymentMethod="Virement bancaire"
      />
      <ProductDetails lessons={lessons !== null ? lessons : []} />
    </div>
  );
};
