import React from 'react';
import { ReactComponent as Checked } from '../../assets/icons/checked.svg';
import './checkbox.scss';

interface Props {
  value: boolean;
  onClick: (val: boolean) => void ;
  children?: JSX.Element;
  missing?: boolean;
}

const CheckBox = ({
  value, onClick, children, missing,
}: Props): JSX.Element => (
  <div role="checkbox" aria-checked={value ? 'true' : 'false'} className="checkbox" tabIndex={0} onClick={(): void => onClick(!value)}>
    {
            missing
              ? (
                <div className={`checkbox-case-missing ${value ? 'checked' : ''}`}>
                  <Checked title="check" />
                </div>
              )
              : (
                <div className={`checkbox-case ${value ? 'checked' : ''}`}>
                  <Checked title="check" />
                </div>
              )
        }
    {children}
  </div>
);

CheckBox.defaultProps = {
  children: null,
  missing: false,
};

export default CheckBox;
