import React from 'react';
import { find } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { Dropdown, Tic, Title } from '..';
import './style.scss';
import {
  selectSelectedStation,
  setReservationSelectedStation,
} from '../../services/reservation/reservationSlice';
import { selectStations } from '../../services/stations/stationsSlice';

export default (): JSX.Element => {
  const stations = useAppSelector(selectStations);
  const selectedStation = useAppSelector(selectSelectedStation);
  const dispatch = useAppDispatch();

  const onUpdate = (value: string): void => {
    const doesStationExist = find(stations, (s) => s.slug === value);
    if (doesStationExist)dispatch(setReservationSelectedStation(doesStationExist));
  };

  return (
    <div className="station-selection-container">
      <Title icon="😎" label="C’est parti ! Trouvez le meilleur cours dans votre station" />
      <div className="lesson-selection-number-selector">
        <Tic />
        Vous souhaitez prendre un cours à
        <Dropdown
          placeholder="station"
          value={selectedStation?.slug}
          options={stations.map((station) => ({ label: station.name, option: station.slug }))}
          onChange={(value: string): void => onUpdate(value)}
        />
      </div>
    </div>
  );
};
