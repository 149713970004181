import React from 'react';

import './publicLessonBlock.scss';

import { Lesson } from '../../../types/lesson';
import { LevelSelector, Checkbox } from '../..';
import { levels } from '../../../constants/tunnelOptions';

interface Props {
  lesson: Lesson;
  onUpdate: (param: {}) => void;
}

export default ({ lesson, onUpdate }: Props): JSX.Element => {
  const { label, description } = levels[lesson.level];
  return (
    <>
      <div className="seller-public-lesson-line">
        <div className="seller-public-lesson-level-title">Niveau :</div>
        <div className="seller-public-lesson-level">
          <LevelSelector
            value={lesson.level}
            onClick={(level: number): void => {
              onUpdate({ level });
            }}
          />
        </div>
        <div className="seller-public-lesson-level-name">
          Niveau:
          {label}
        </div>
        <div className="public-lesson-level-description">{description}</div>
      </div>
      <Checkbox
        value={!!lesson.levelUncertain}
        onClick={(): void => onUpdate({ levelUncertain: !lesson.levelUncertain })}
      >
        <span className="seller-public-lesson-level-checkbox">Pas certain du niveau ?</span>
      </Checkbox>
      {/* <div className="seller-public-lesson-warning">Malheureusement il n'y a plus de cours
      disponibles l'après-midi, changer votre séléction pour trouver un cours</div> */}
    </>
  );
};
