import React, { useEffect } from 'react';

import './input.scss';

interface Props {
  type: string;
  value: any;
  onChange: (val: any) => void;
  placeholder?: string;
  extraClass?: string;
  name?: string;
  register?: any;
  rule?: Record<string, any>;
  errors?: any;
  extraContainerClass?: any;
}

const dropdown = (
  {
    type, value, onChange, placeholder, extraClass, name, register,
    rule, errors, extraContainerClass,
  }: Props,
): JSX.Element => {
  useEffect(() => {
    if (register && name && rule) register({ name }, rule);
    // eslint-disable-next-line
    }, [])

  return errors || extraContainerClass
    ? (
      <div className={`input-container ${extraContainerClass}`}>
        <input
          className={`input ${extraClass}`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e): void => onChange(e.target.value)}
          name={name}
        />
        {errors && name && <span className="span-error">{errors[name]?.message}</span>}
      </div>
    )
    : (
      <input
        className={`input ${extraClass}`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e): void => onChange(e.target.value)}
        name={name}
      />
    );
};

dropdown.defaultProps = {
  name: '',
  rule: null,
  errors: null,
  register: null,
  error: false,
  customStyle: '',
  customOptionStyle: '',
};

export default dropdown;
