import React from 'react';
import { find } from 'lodash';

import { Dropdown, Tic } from '../..';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import './style.scss';
import {
  selectSelectedStation,
  setReservationSelectedStation,
} from '../../../services/reservation/reservationSlice';
import { selectStations } from '../../../services/stations/stationsSlice';

export default (): JSX.Element => {
  const stations = useAppSelector(selectStations);
  const selectedStation = useAppSelector(selectSelectedStation);
  const dispatch = useAppDispatch();

  const onUpdate = (value: string): void => {
    const doesStationExist = find(stations, (s) => s.slug === value);
    if (doesStationExist) dispatch(setReservationSelectedStation(doesStationExist));
  };

  return (
    <div className="seller-station-selection-container">
      <div className="seller-station-selection-number-selector">
        <Tic />
        Réserver des cours à
        <Dropdown
          placeholder="station"
          value={selectedStation?.slug}
          options={stations.map((station) => ({ label: station.name, option: station.slug }))}
          onChange={(value: string): void => onUpdate(value)}
        />
      </div>
    </div>
  );
};
