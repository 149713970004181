// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';

import './studentForm.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from '../../hooks';
import { Dropdown, DropdownMultipleSelect, Input } from '../index';
import { updateUserStudents } from '../../services/users/userSlice';

interface Props {
  id: number;
  referentId: number;
  genders: Array<{ id: number; name: string }>;
  languagesOptions: Array<{ id: number; name: string }>;
}

export default ({
  id, referentId, genders, languagesOptions,
}: Props): JSX.Element => {
  const [firstName, setFirstName] = useState<string>('');
  const [gender, setGender] = useState<number>(-1);
  const [age, setAge] = useState<number>();
  const [languages, setLanguages] = useState<Array<number>>([]);
  const [comment, setComment] = useState<string>('');
  const dispatch = useAppDispatch();

  const { setValue, register, errors } = useFormContext();

  useEffect(() => {
    dispatch(updateUserStudents({
      id,
      firstName,
      gender,
      age: age || -1,
      languages: languages || [],
      comment,
      referentId,
    }));
  }, [firstName, gender, age, languages, comment, id, referentId, dispatch]);

  const addOrDeleteLanguage = (v: string): Array<number> => (
    !languages.find((l) => l === parseInt(v, 10))
      ? [...languages, parseInt(v, 10)]
      : [...languages].filter((item) => item !== parseInt(v, 10))
  );

  return (
    <div className="stud-form-container">
      <h3>
        Profil éleve
        {id + 1}
      </h3>
      <div className="stud-form-input-group">
        <Input
          type="text"
          placeholder="Prénom *"
          value={firstName}
          onChange={(v): void => {
            setFirstName(v);
            setValue(`studentFirstName${id}`, v);
          }}
          name={`studentFirstName${id}`}
          register={register}
          rule={{ required: 'Ce champ est requis' }}
          errors={errors}
          extraContainerClass="stud-form-input-container"
        />
        <Dropdown
          placeholder="Genre *"
          value={gender}
          options={genders.map((o) => ({ label: o.name, option: o.id }))}
          onChange={(v: string): void => {
            setGender(parseInt(v, 10));
            setValue(`studentGender${id}`, v);
          }}
          name={`studentGender${id}`}
          rule={{ validate: (value: number): string | boolean => (value >= 0 ? true : 'Ce champs est requis') }}
          errors={errors}
          register={register}
          customStyle="input stud-form-dropdown"
          customOptionStyle="stud-form-dropdown-options"
        />
        <Input
          type="number"
          placeholder="age *"
          value={age}
          register={register}
          onChange={(v): void => {
            setAge(v);
            setValue(`studentAge${id}`, v);
          }}
          name={`studentAge${id}`}
          rule={{
            required: 'Ce champ est requis',
            validate: (value: number): boolean | string => (value >= 0 ? true : 'Ce champ est mal formaté'),
          }}
          extraContainerClass="stud-form-input-container"
          errors={errors}
        />
        <DropdownMultipleSelect
          placeholder="Langues *"
          data={languages}
          options={languagesOptions.map((o) => ({ label: o.name, value: o.id }))}
          onChange={(v: string): void => {
            const selectedLanguages = addOrDeleteLanguage(v);
            setLanguages(selectedLanguages);
            setValue(`studentLanguage${id}`, languages);
          }}
          name={`studentLanguage${id}`}
          rule={{
            required: 'Ce champ est requis',
            validate: (value: Array<number>): boolean => value.length > 0,
          }}
          errors={errors}
          register={register}
          customStyle="input stud-form-dropdown"
          customOptionStyle="stud-form-dropdown-options"
        />
        <Input
          type="text"
          placeholder="Commentaires"
          value={comment}
          onChange={setComment}
          extraContainerClass="stud-form-input-container stud-form-input-comment"
        />
      </div>
    </div>
  );
};
