import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Station, Stations } from '../../types/station';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';

/* eslint-disable no-param-reassign */

const initialState: Stations = {
  stations: [],
};
export const stationsSlice = createSlice(
  {
    name: 'stations',
    initialState,
    reducers: {
      updateStations: (state, action:PayloadAction<Station[]>) => {
        state.stations = [...state.stations, ...action.payload];
      },
    },
  },
);

export const { updateStations } = stationsSlice.actions;
export const selectStations = (state:RootState) => state.stations.stations;

export default stationsSlice.reducer;
