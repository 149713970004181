import React, { useEffect, useState } from 'react';
import calendar from '../../assets/gifs/calendar.gif';
import LinearProgressWithLabel from '../linearProgressWithLabel/linearProgressWithLabel';
import { Title } from '../index';

const AvailabilityLoading = () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10;
        }
        return prevProgress;
      });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <img height={300} src={calendar} alt="chargement" />
      <LinearProgressWithLabel value={progress} />
      <div className="calendar-loader-text-wrapper">
        <Title label="Nous consultons les disponibilités des moniteurs" />
        <p>Patience, plus que quelques secondes...</p>
      </div>
    </>
  );
};

export default AvailabilityLoading;
