import { Moment } from 'moment/moment';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { DatePicker, Tic, Title } from '..';
import './style.scss';
import {
  selectArrivalDate,
  selectDepartureDate,
  selectSelectedStation,
  setReservationDateFrom,
  setReservationDateTo,
} from '../../services/reservation/reservationSlice';

export default (): JSX.Element => {
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const selectedStation = useAppSelector(selectSelectedStation);
  const dispatch = useAppDispatch();

  const onDateChange = useCallback((values:{ from:Moment|null, to:Moment|null }) => {
    if (values.from !== null)dispatch(setReservationDateFrom(values.from.toISOString()));
    if (values.to !== null)dispatch(setReservationDateTo(values.to.toISOString()));
  }, [dispatch]);

  return (
    <div className="lesson-selection-container">
      <Title icon="🗓" label={`A quelles dates serez-vous sur ${selectedStation?.name} ?`} />
      <div className="lesson-selection-number-selector">
        <Tic />
        Mon séjour à lieu du
        <DatePicker onChange={(values): void => onDateChange(values)} placeholder="date" from={dateFrom} to={dateTo} />
      </div>
    </div>
  );
};
