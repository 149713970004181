import axios, { AxiosResponse } from 'axios';

axios.defaults.timeout = 15000;

const generateTokenHeader = (): Record<string, unknown> => (localStorage.getItem('api-token') ? {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('api-token')}`,
  },
} : {});

export const refresh = (
  onSuccess: (res: any) => void,
  onFail: (err: any) => void,
): void => {
  if (localStorage.getItem('api-refresh')) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/token/refresh/`, {
        refresh: localStorage.getItem('api-refresh'),
      })
      .then((res) => {
        localStorage.setItem('api-token', res.data.access);
        onSuccess(res);
      })
      .catch((err) => {
        localStorage.removeItem('api-token');
        localStorage.removeItem('api-refresh');
        onFail(err);
      });
  } else {
    localStorage.removeItem('api-token');
    localStorage.removeItem('api-refresh');
    onFail('Refresh token not found');
  }
};

export const auth = (
  username: string,
  password: string,
  onSuccess: (res: any) => void,
  onFail: (err: any) => void,
): void => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/token/`, {
      username,
      password,
    })
    .then((res) => {
      localStorage.setItem('api-refresh', res.data.refresh);
      localStorage.setItem('api-token', res.data.access);
      onSuccess(res);
    })
    .catch((err) => onFail(err));
};

export const get = (
  target: string,
  onSuccess: (res: AxiosResponse<any>) => any,
  onFail: (err: any) => any,
): void => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api${target}`, generateTokenHeader())
    .then((res) => onSuccess(res))
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        refresh(() => get(target, onSuccess, onFail), onFail);
      } else {
        localStorage.removeItem('api-token');
        localStorage.removeItem('api-refresh');
        onFail(err);
      }
    });
};

export const post = (
  target: string,
  data: any,
  onSuccess: (res: any) => any,
  onFail: (err: any) => any,
): void => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api${target}`, data, generateTokenHeader())
    .then((res) => onSuccess(res))
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        refresh(() => post(target, data, onSuccess, onFail), onFail);
      } else {
        localStorage.removeItem('api-token');
        localStorage.removeItem('api-refresh');
        onFail(err);
      }
    });
};

export const put = (
  target: string,
  data: any,
  onSuccess: (res: any) => any,
  onFail: (err: any) => any,
): void => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api${target}`, data, generateTokenHeader())
    .then((res) => onSuccess(res))
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        refresh(() => put(target, data, onSuccess, onFail), onFail);
      } else {
        localStorage.removeItem('api-token');
        localStorage.removeItem('api-refresh');
        onFail(err);
      }
    });
};

export const apiDelete = (
  target: string,
  onSuccess: (res: any) => any,
  onFail: (err: any) => any,
): void => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api${target}`, generateTokenHeader())
    .then((res) => onSuccess(res))
    .catch((err) => {
      localStorage.removeItem('api-token');
      if (err && err.response && err.response.status === 401) {
        refresh(() => apiDelete(target, onSuccess, onFail), onFail);
      } else {
        localStorage.removeItem('api-token');
        localStorage.removeItem('api-refresh');
        onFail(err);
      }
    });
};
