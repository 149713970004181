import React from 'react';

import '../lessonDetails/style.scss';

import { RadioGroup } from '../..';
import SellerPrivateLessonBlock from '../privateLessonBlock/privateLessonBlock';
import { nbLessonOptions } from '../../../constants/tunnelOptions';
import { selectLessons } from '../../../services/lessons/lessonsSlice';
import { useAppSelector } from '../../../hooks';
import { selectArrivalDate } from '../../../services/reservation/reservationSlice';
import { selectProducts } from '../../../services/products/productsSlice';

interface Props {
  index: number;
  onChange: (payload: object) => void;
}

export default ({ index, onChange }: Props): JSX.Element => {
  const products = useAppSelector(selectProducts);
  const dateFrom = useAppSelector(selectArrivalDate);
  const lessons = useAppSelector(selectLessons);

  return (
    <>
      <div className="lesson-details-line">
        <div className="lesson-details-line">
          <div className="lesson-details-description">
            Quantité:
          </div>
          <RadioGroup
            selected={lessons[index].nbLessons}
            options={nbLessonOptions}
            onClick={(value): void => onChange({ nbLessons: value })}
          />
        </div>
      </div>
      {products !== null
        ? (
          <SellerPrivateLessonBlock
            lesson={lessons[index]}
            date={dateFrom}
            products={products}
          />
        ) : null}
    </>
  );
};
