import React from 'react';

import './radiogroup.scss';

interface Props {
  options: Array<{
    label: string;
    value: number | string | boolean;
  }>;
  selected: number | string | boolean | null;
  onClick: (value: number | string | boolean) => void;
  scrollOnMobile?: boolean;
  validation?: boolean;
  disabled?:Array<number|string|boolean>
}

const RadioGroup = ({
  options, selected, onClick, scrollOnMobile, validation, disabled,
}: Props): JSX.Element => (
  <div role="radiogroup" className={`radio-group-container ${scrollOnMobile ? 'scroller' : ''} ${validation && (selected === null || selected === 0) && 'error'}`}>
    <div className="radio-group-options">
      {options.map(({ label, value }) => (
        <div
          role="radio"
          tabIndex={0}
          key={`RadioGroupFor${label}`}
          className={`radio-group-option ${value === selected ? 'selected' : ''} ${disabled?.includes(value) ? 'disabled' : ''}`}
          onClick={(): void => onClick(value)}
          aria-checked={value === selected}
        >
          {label}
        </div>
      ))}
    </div>
  </div>
);

RadioGroup.defaultProps = {
  scrollOnMobile: true,
  validation: false,
  disabled: [],
};
export default RadioGroup;
