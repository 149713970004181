import React from 'react';

import '../privateLessonBlock/privateLessonBlock.scss';
import { Instructor } from '../../types/instructor';

interface Props {
  instructors: Array<Instructor>;
}

export default ({ instructors }: Props): JSX.Element => (
  <div className="seller-private-lesson-block-instructors">
    <span role="img" aria-label="Hi">👋</span>
    {' '}
    {instructors[0].firstName}
    {instructors.length === 1 && ' est disponible '}
    {instructors.length === 2 && ` et ${instructors[1].firstName}`}
    {instructors.length > 2 && `, ${instructors[1].firstName} et ${instructors.length - 2} autres moniteurs`}
    {instructors.length > 1 && ' sont disponibles '}
    à ces horaires
  </div>
);
