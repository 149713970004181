import React, { useCallback, useMemo } from 'react';
import { Checkbox, LessonRecapData } from '..';
import './lessonRecapBlock.scss';
import { getAgeLabel, getLevelLabel } from '../../helpers/lesson';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { Lesson } from '../../types/lesson';
import { CHILDREN, MORNING, SUNDAY } from '../../constants/constants';

interface Props {
  lesson: Lesson;
  onClick: (index: number, target: string) => void;
  onChange: (data: Partial<Lesson>) => void;
  onDelete: (data: Lesson) => void;
}

export default ({
  lesson,
  onClick,
  onDelete,
  onChange,
}: Props): JSX.Element => {
  const ageLabel = useMemo(() => getAgeLabel(lesson), [lesson]);
  const levelLabel = useMemo(() => getLevelLabel(lesson), [lesson]);

  const renderPassOption = useCallback(() => {
    const typePart = lesson.level < 2 ? 'débutant' : 'confirmé';
    const agePart = lesson.age === CHILDREN ? 'enfant' : 'adulte';
    const mentionPart = lesson.level < 2 ? (
      <>
        et économisez jusqu&apos;à
        <span>20%</span>
      </>
    ) : 'qui vous sera directement remis par votre moniteur';

    return (
      <Checkbox
        value={lesson.pass}
        onClick={(): void => onChange({ pass: !lesson.pass })}
      >
        <div className={`lesson-recap-detail-pass-text ${lesson.pass ? 'checked' : ''}`}>
          Réservez un
          {' '}
          <span>forfait</span>
          {' '}
          {typePart}
          {' '}
          {agePart}
          {' '}
          6 jours
          {' '}
          {mentionPart}
        </div>
      </Checkbox>
    );
  }, [lesson, onChange]);
  return (
    <div className="lesson-recap-detail-block">
      <div className="lesson-recap-detail-header">
        <div className="lesson-recap-detail-header-left">
          <div className="lesson-recap-detail-index">
            #
            {lesson.id + 1}
          </div>
          <div
            className="lesson-recap-detail-privacy"
          >
            {lesson.isPrivate ? 'Cours privé' : 'Cours collectif'}
          </div>
        </div>
        <div className="lesson-recap-detail-header-right">
          <div className="lesson-recap-detail-price">
            {lesson.price}
            €
          </div>
          <div
            tabIndex={0}
            role="button"
            className="lesson-recap-detail-delete"
            onClick={(): void => onDelete(lesson)}
          >
            <Delete title="delete" />
          </div>
        </div>
      </div>
      <div className="lesson-recap-detail-data">
        {!lesson.isPrivate ? (
          <LessonRecapData
            text={ageLabel || ''}
            onClick={(): void => onClick(lesson.id, '/reservation/lessons')}
          />
        ) : (
          <LessonRecapData
            text={`${lesson.nbPeople} personne(s)`}
            onClick={(): void => onClick(lesson.id, '/reservation/lessons')}
          />
        )}
        {!lesson.isPrivate ? (
          <LessonRecapData
            text={lesson.startDay === SUNDAY ? '6 jours' : '5 jours'}
            onClick={(): void => onClick(lesson.id, '/reservation/details')}
          />
        ) : (
          <LessonRecapData
            text={`${lesson.nbLessons} sessions`}
            onClick={(): void => onClick(lesson.id, '/reservation/details')}
          />
        )}
        {!lesson.isPrivate && (
        <>
          <LessonRecapData
            text={lesson.timeslot === MORNING ? 'Matin' : 'Après midi'}
            onClick={(): void => onClick(lesson.id, '/reservation/details')}
          />
          <LessonRecapData
            text={`A partir du ${lesson.startDay === SUNDAY ? 'dimanche' : 'lundi'}`}
            onClick={(): void => onClick(lesson.id, '/reservation/details')}
          />
          <LessonRecapData
            text={levelLabel || ''}
            onClick={(): void => onClick(lesson.id, '/reservation/details')}
          />
        </>
        )}
      </div>
      {!lesson.isPrivate && renderPassOption()}
    </div>
  );
};
