import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { Tic } from '..';
import LessonDropdown from '../lessonDropdown/lessonDropdown';
import NbPeopleSelector from '../nbPeopleSelector/nbPeopleSelector';
import ActivitySelector from '../activitySelector/activitySelector';
import AgeSelector from '../ageSelector/ageSelector';
import './style.scss';
import { removeLesson, updateLesson } from '../../services/lessons/lessonsSlice';
import { Lesson } from '../../types/lesson';

const Lines = (index?: number): Array<string> => [
  'Super ! Pour le premier cours, vous préférez',
  'On enchaîne ! Pour le second cours, vous préférez',
  `Pour le ${index}ème cours, vous préférez`,
];

interface Props {
  lesson: Lesson;
  showError: boolean;
}

export default ({ lesson, showError }: Props): JSX.Element => {
  const [firstChoiceDone, setFirstChoiceDone] = useState((lesson.isPrivate || lesson.activity !== ''));
  const dispatch = useAppDispatch();

  const renderLineText = (): string => Lines(lesson.id + 1)[lesson.id < 2 ? lesson.id : 2];

  const onPrivacyChange = (value: boolean): void => {
    setFirstChoiceDone(true);
    dispatch(updateLesson({ ...lesson, ...{ isPrivate: value } }));
  };

  const onDelete = (): void => {
    dispatch(removeLesson(lesson));
  };

  return (
    <>
      <div className="lesson-line">
        <Tic />
        {`${renderLineText()}`}
        <LessonDropdown
          placeholder="un cours privé ou collectif ?"
          selected={lesson}
          isEmpty={!firstChoiceDone}
          onChange={onPrivacyChange}
          error={showError && !firstChoiceDone}
        />
        {firstChoiceDone && (lesson.isPrivate
          ? <NbPeopleSelector lesson={lesson} showError={showError} />
          : <ActivitySelector lesson={lesson} showError={showError} />
        )}
        <div className="lesson-delete" role="button" tabIndex={0} onClick={(): void => onDelete()}>x</div>
      </div>
      {firstChoiceDone && !lesson.isPrivate
      && <AgeSelector lesson={lesson} showError={showError} /> }
    </>
  );
};
