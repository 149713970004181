import React from 'react';

import './title.scss';

interface Props {
  label: string;
  icon?: string;
}

const Title = ({ label, icon }: Props): JSX.Element => (
  <div className="title-container">
    {icon && <div className="title-icon">{icon}</div>}
    <div className="title-label">{label}</div>
  </div>
);

Title.defaultProps = {
  icon: '',
};

export default Title;
