import React from 'react';

import './style.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import SellerPrivateLessonDetails from '../privateLessonDetails/privateLessonDetails';
import SellerPublicLessonDetails from '../publicLessonDetails/publicLessonDetails';
import { selectLessons, updateLesson } from '../../../services/lessons/lessonsSlice';
import { Lesson } from '../../../types/lesson';

interface Props {
  index: number;
}

export default ({ index }: Props): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const dispatch = useAppDispatch();

  const onDetailsChange = (data: Partial<Lesson>): void => {
    dispatch(updateLesson(data));
  };

  return (
    <div className="lesson-details-container">
      {lessons[index].isPrivate
        ? <SellerPrivateLessonDetails index={index} onChange={onDetailsChange} />
        : <SellerPublicLessonDetails index={index} onChange={onDetailsChange} />}
    </div>
  );
};
