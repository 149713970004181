import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
import { get } from 'lodash';

import './style.scss';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

const { RangePicker } = DatePicker;

interface Props {
  onChange: (value: { from: Moment | null; to: Moment | null }) => void;
  placeholder: string;
  from: moment.Moment | null;
  to: moment.Moment | null;
}

export default ({
  placeholder,
  onChange,
  from,
  to,
}: Props): JSX.Element => {
  const [opened, setOpened] = useState(false);
  moment.locale('fr');

  const renderHeader = () => (
    <div className="date-picker-instructions">
      Sélectionnez une date de début et une date de fin sur le calendrier
    </div>
  );

  const disabledDate = (current: Moment) => current && current < moment()
    .endOf('day');

  return (
    <div className="date-picker">
      <RangePicker
        disabledDate={disabledDate}
        value={[from, to]}
        className={`date-picker-top ${from && to ? 'filled' : ''}`}
        dropdownClassName="date-picker-bottom"
        onChange={(v): void => {
          onChange({
            from: get(v, 0, null),
            to: get(v, 1, null),
          });
        }}
        placeholder={[placeholder, placeholder]}
        onOpenChange={(open) => setOpened(open)}
        format="D MMMM"
        renderExtraFooter={renderHeader}
        separator="au"
        inputReadOnly
      />
      {opened && <div className="date-picker-background" />}
      <div className="date-picker-label" />
      {opened ? <ArrowUp title="up" className="date-picker-arrow" />
        : <ArrowDown title="down" className="date-picker-arrow" />}
    </div>
  );
};
