import React from 'react';
import { levels } from '../../constants/tunnelOptions';
import { LessonLevel } from '../../types/lesson';
import './style.scss';

interface Props {
  value: number;
  onClick: (value: number) => void;
}

export default ({ value, onClick }: Props): JSX.Element => (
  <div className="level-selector">
    <div className="level-selector-background">
      <div className="level-selector-line" />
    </div>
    <div role="listbox" className="level-selector-values">
      {levels.map((level: LessonLevel) => (
        <div tabIndex={0} role="option" aria-selected={value === level.value} key={`levelSelector${level.label}`} className="level-selector-value" onClick={() => onClick(level.value)}>
          <div className={`level-selector-icon ${level.color} ${value === level.value ? 'selected' : ''}`} />
        </div>
      ))}
    </div>
  </div>
);
