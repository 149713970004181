import React from 'react';
import './style.scss';
import { Deal } from '../../types/deal';
import { Lesson } from '../../types/lesson';

interface Props {
  title: string;
  deals: Array<Deal>;
  lesson: Lesson;
}

const tagColors = ['red', 'blue', 'green'];

export default ({ title, deals, lesson }: Props): JSX.Element => (
  <div className="dealblock-container">
    <div className="dealblock-top">
      <div className="dealblock-header"><span role="img" aria-label="ok">👍</span></div>
      <div className="dealblock-title">{title}</div>
      {deals.map((deal) => (
        <div key={`Deal${deal.label}`} className="dealblock-deal">
          <div className="dealblock-label">{deal.label}</div>
          <div className="dealblock-tags">
            {deal.tags.map((tag, index) => (
              <div className="dealblock-tag">
                <div className="dealblock-tag-label">{tag.label}</div>
                <div className={`dealblock-tag-discount ${tagColors[index % 3]}`}>{tag.discount}</div>

              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
    <div className="dealblock-bottom">
      <div className="dealblock-price-title">Prix</div>
      <div className="dealblock-price">
        {lesson.price}
        {' '}
        €
      </div>
    </div>
  </div>
);
