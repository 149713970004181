// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';

import './referentForm.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from '../../hooks';
import { Input } from '../index';
import { updateUserReferents } from '../../services/users/userSlice';

interface Props {
  id: number;
}

export default ({ id }: Props): JSX.Element => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const dispatch = useAppDispatch();

  const { setValue, register, errors } = useFormContext();

  useEffect((): void => {
    dispatch(updateUserReferents({
      firstName,
      lastName,
      tel,
      email,
      address,
    }));
  }, [firstName, lastName, tel, email, address, id, dispatch]);

  return (
    <div className="ref-form-container">
      <h3>Contact Référent</h3>
      <div className="ref-form-input-group">
        <Input
          type="text"
          placeholder="Prénom *"
          value={firstName}
          onChange={(v): void => {
            setFirstName(v);
            setValue(`referentFirstName${id}`, v);
          }}
          name={`referentFirstName${id}`}
          register={register}
          rule={{ required: 'Ce champ est requis' }}
          errors={errors}
          extraContainerClass="ref-form-input-container"
        />
        <Input
          type="text"
          placeholder="Nom *"
          value={lastName}
          onChange={(v): void => {
            setLastName(v);
            setValue(`referentLastName${id}`, v);
          }}
          name={`referentLastName${id}`}
          register={register}
          rule={{ required: 'Ce champ est requis' }}
          errors={errors}
          extraContainerClass="ref-form-input-container"
        />
        <Input
          type="text"
          placeholder="Téléphone *"
          value={tel}
          onChange={(v): void => {
            setTel(v);
            setValue(`referentTel${id}`, v);
          }}
          name={`referentTel${id}`}
          register={register}
          rule={{
            required: 'Ce champ est requis',
            validate: (value: string): boolean | string => {
              const cleanedValue = `${value}`.replace('+', '00').replace(/\s/g, '');
              return (
                cleanedValue.match(/(?:\d{13}|\d{10})/i) !== null
                                   && (cleanedValue.length === 10 || cleanedValue.length === 13)
                  ? true : 'Ce champ est mal formaté'
              );
            },
          }}
          errors={errors}
          extraContainerClass="ref-form-input-container"
        />
        <Input
          type="text"
          placeholder="E-mail *"
          value={email}
          onChange={(v): void => {
            setEmail(v);
            setValue(`referentEmail${id}`, v);
          }}
          name={`referentEmail${id}`}
          register={register}
          rule={{
            required: 'Ce champ est requis',
            /* Matches any string like :   any 1+ char @ any 1+ char . any 2+ char */
            validate: (value: string): boolean | string => (value.match(/(.+@.+[.].{2,})/i) != null ? true : 'Ce champ est mal formaté'),
          }}
          errors={errors}
          extraContainerClass="ref-form-input-container"
        />
        <Input
          type="text"
          placeholder="Adresse de résidence (sur place)"
          value={address}
          onChange={setAddress}
          extraContainerClass="ref-form-input-container ref-form-input-address"
        />
      </div>
    </div>
  );
};
