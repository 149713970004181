import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product, Products } from '../../types/product';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';

/* eslint-disable no-param-reassign */

const initialState: Products = {
  products: null,
};
export const productsSlice = createSlice(
  {
    name: 'products',
    initialState,
    reducers: {
      setProducts: (state, action: PayloadAction<Product[]>) => {
        state.products = action.payload;
      },
      addProduct: (state, action: PayloadAction<Product>) => {
        if (state.products !== null) {
          state.products = state.products.concat(action.payload);
        } else {
          state.products = [action.payload];
        }
      },
      removeProduct: (state, action: PayloadAction<number>) => {
        if (state.products !== null) {
          state.products = state.products.filter((_lesson, index) => index !== action.payload);
        }
      },
      updateProduct: (state, action: PayloadAction<Product>) => {
        if (state.products !== null) {
          state.products = state.products.map((product) => (
            product.id === action.payload.id ? { ...product, ...action.payload } : product));
        }
      },
    },
  },
);
export const {
  addProduct,
  removeProduct,
  updateProduct,
  setProducts,
} = productsSlice.actions;

export const selectProducts = (state: RootState) => state.products.products;

export default productsSlice.reducer;
