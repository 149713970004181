import React, { useEffect, useState } from 'react';

import './tunnel-informations.scss';

import { Redirect } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import {
  Button, LessonRecapTotal, PaymentForm, Title,
} from '..';
import { post } from '../../api/api';
import {
  selectClientSecret,
  selectOrderId,
  setClientSecret,
} from '../../services/clientSecret/clientSecretSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectProducts } from '../../services/products/productsSlice';
import { selectLessons } from '../../services/lessons/lessonsSlice';
import { Product } from '../../types/product';

export default (): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const clientSecret = useAppSelector(selectClientSecret);
  const orderId = useAppSelector(selectOrderId);
  const products = useAppSelector(selectProducts);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const [paymentError, setPaymentError] = useState('');
  const dispatch = useAppDispatch();

  const verifyTotal = (): void => {
    if (clientSecret === '') {
      const selectedProducts = Array.from(new Set(lessons
        .flatMap((lesson) => lesson.lessonDays.map((lessonDay) => lessonDay.lesson))))
        .map((id) => products?.find((p) => p.id === id))
        .filter((product):product is Product => !!product);
      post('/webhooks/stripe/generateIntent/',
        { products: selectedProducts },
        (res) => {
          setIsVerified(true);
          if (res.data.secret && res.data.orderId) {
            dispatch(setClientSecret({ clientSecret: res.data.secret, orderId: res.data.orderId }));
          } else {
            dispatch(setClientSecret({ clientSecret: '', orderId: -1 }));
          }
        },
        () => {
          setIsVerified(true);
          dispatch(setClientSecret({ clientSecret: '', orderId: -1 }));
        });
    } else {
      post('/webhooks/stripe/updateIntent/',
        {
          products,
          orderId,
        },
        (res) => {
          setIsVerified(true);
          if (res.data.secret && res.data.orderId) {
            dispatch(setClientSecret(
              { clientSecret: res.data.secret, orderId: res.data.orderId },
            ));
          } else dispatch(setClientSecret({ clientSecret: '', orderId: -1 }));
        },
        () => {
          setIsVerified(true);
          dispatch(setClientSecret({ clientSecret: '', orderId: -1 }));
        });
    }
  };

  useEffect(() => {
    verifyTotal();
    // eslint-disable-next-line
    }, [])

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      {(isVerified || clientSecret !== '')
        ? (
          <>
            {!paymentError
              ? (
                <div className="tunnel-informations">
                  <div className="tunnel-informations-left">
                    <Title icon="🤓" label="Vos informations pour votre commande" />
                    <div className="tunnel-informations-text">
                      {`Une fois le paiement effectué, l'équipe Ski Family 
                      vous transmettra un questionnaire pour renseigner les infos sur 
                      les différents participants aux cours que vous venez de réserver.`}
                    </div>
                    <PaymentForm
                      setRedirect={setRedirect}
                      setPaymentError={setPaymentError}
                    />
                  </div>
                  <div className="tunnel-informations-right">
                    <LessonRecapTotal />
                  </div>
                </div>
              )
              : (
                <div className="tunnel-informations-error-container">
                  <div className="tunnel-informations-error">
                    <div>
                      Paiement refusé :
                      {paymentError}
                    </div>
                    <div>
                      Veuillez contactez le
                      {' '}
                      <strong>04 81 68 09 06</strong>
                      .
                    </div>
                    <Button
                      className="redirection-button"
                      label="Revenir aux détails de la commande"
                      onClick={(): void => setRedirect('/reservation/recap')}
                    />
                  </div>
                </div>
              )}
          </>
        )
        : (
          <div className="tunnel-informations-loader-container">
            <MoonLoader
              css="margin: auto;"
              color="#5A3FDC"
              size={50}
              loading
            />
          </div>
        )}
    </>
  );
};
