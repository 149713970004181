import React from 'react';
import { useAppDispatch } from '../../hooks';
import { Dropdown } from '..';
import './style.scss';
import { nbPeopleDropdownOptions } from '../../constants/tunnelOptions';
import { updateLesson } from '../../services/lessons/lessonsSlice';
import { Lesson } from '../../types/lesson';

interface Props {
  lesson: Lesson;
  showError: boolean;
}

export default ({ lesson, showError = false }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const onNbPeopleChange = (value: number): void => {
    dispatch(updateLesson({ ...lesson, ...{ nbPeople: value } }));
  };

  return (
    <>
      pour
      <Dropdown
        placeholder="nombre de personnes"
        value={lesson.nbPeople}
        options={nbPeopleDropdownOptions}
        onChange={onNbPeopleChange}
        error={showError && !lesson.nbPeople}
      />
    </>
  );
};
