import { configureStore } from '@reduxjs/toolkit';
/* eslint-disable import/no-cycle */
import lessonsReducer from './services/lessons/lessonsSlice';
import reservationReducer from './services/reservation/reservationSlice';
import stationsReducer from './services/stations/stationsSlice';
import productsReducer from './services/products/productsSlice';
import userReducer from './services/users/userSlice';
import clientSecretReducer from './services/clientSecret/clientSecretSlice';

export const store = configureStore({
  reducer: {
    lessons: lessonsReducer,
    reservation: reservationReducer,
    stations: stationsReducer,
    products: productsReducer,
    user: userReducer,
    clientSecret: clientSecretReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
