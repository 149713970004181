import React from 'react';

import './productDetails.scss';
import { Lesson } from '../../types/lesson';

interface Props {
  lessons: Array<Lesson>;
}

export default ({ lessons }: Props): JSX.Element => (
  <div className="product-details">
    <div className="product-details-row-0">
      <div className="product-details-right product-details-main">
        Produit :
      </div>
      <div className="product-details-main">
        Total :
      </div>
    </div>
    {lessons.map((lesson) => (
      <div className={`product-details-row-${(lesson.id + 1) % 2}`} key={lesson.id.toString()}>
        <div className="product-details-right">
          <div className="product-details-main">
            Cours
            {lesson.isPrivate ? 'Privé' : 'Collectif'}
          </div>
        </div>
        <div>{lesson.price}</div>
      </div>
    ))}
  </div>
);
