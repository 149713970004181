import { Moment } from 'moment/moment';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { DatePicker, Tic } from '../..';
import './style.scss';
import {
  selectArrivalDate,
  selectDepartureDate,
  setReservationDateFrom,
  setReservationDateTo,
} from '../../../services/reservation/reservationSlice';

export default (): JSX.Element => {
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const dispatch = useAppDispatch();

  const onDateChange = useCallback((values:{ from:Moment|null, to:Moment|null }) => {
    if (values.from !== null && values.to !== null) {
      dispatch(setReservationDateFrom(values.from.toISOString()));
      dispatch(setReservationDateTo(values.to.toISOString()));
    }
  }, [dispatch]);

  return (
    <div className="seller-date-selection-container">
      <div className="seller-date-selection-number-selector">
        <Tic />
        Dates de séjour :
        <DatePicker onChange={(values): void => onDateChange(values)} placeholder="date" from={dateFrom} to={dateTo} />
      </div>
    </div>
  );
};
