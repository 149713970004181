import React, { useEffect, useRef, useState } from 'react';

import './style.scss';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

interface Props {
  placeholder: string;
  options: Array<{
    label: string;
    value: any;
  }>;
  data: any;
  onChange: (value: any) => void;
  name?: string;
  rule?: Record<string, any>;
  errors?: any;
  register?: any;
  error?: boolean;
  customStyle?: string;
  customOptionStyle?: string;
}

const dropdown = (
  {
    placeholder, options, data, onChange, name, rule, errors, register,
    error, customStyle, customOptionStyle,
  }: Props,
): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (register && rule && name) register({ name }, rule);
  }, [register]);

  useEffect(() => {
    const handleOutsideClick = (event: any): void => {
      if (ref.current && !ref.current?.contains(event.target)) {
        setOpened(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);

  const valueLabel = data.map((id: number) => options.find((o) => o.value === id)?.label).join(', ');

  const dropdownComponent = (): JSX.Element => (
    <div
      className={`dropdown-container ${customStyle} ${error && 'error'}`}
      onClick={(): void => setOpened(true)}
      role="button"
      tabIndex={0}
    >
      <button type="button" className={`dropdown-label ${valueLabel ? 'filled' : ''}`}>
        {valueLabel || placeholder}
      </button>
      {opened ? <ArrowUp title="up" className="dropdown-arrow" />
        : <ArrowDown title="down" className="dropdown-arrow" />}
      {opened && (
        <div role="listbox" className={`dropdown-options-container ${customOptionStyle}`} ref={ref}>
          <div className="dropdown-background" />
          <div className="dropdown-options">
            {options.map(({ label, value }) => (data.find((v: number) => v === value)
              ? (
                <div
                  role="option"
                  aria-selected
                  key={`Option${label}`}
                  onClick={(): void => {
                    onChange(value);
                  }}
                  className="dropdown-option dropdown-option-selected"
                  tabIndex={0}
                >
                  {label}
                  <div className="check">
                    ✓
                  </div>
                </div>
              )
              : (
                <div
                  role="option"
                  key={`Option${label}`}
                  onClick={(): void => {
                    onChange(value);
                  }}
                  className="dropdown-option"
                  tabIndex={0}
                  aria-selected={false}
                >
                  {label}
                </div>
              )))}
          </div>
        </div>
      )}
    </div>
  );

  return register ? (
    <>
      <input style={{ display: 'none' }} name={name} value={data} readOnly type="text" />
      <div className="dropdown-component">
        {dropdownComponent()}
        {errors && name && <span className="span-error">{errors[name]?.message}</span>}
      </div>
    </>
  )
    : dropdownComponent();
};

dropdown.defaultProps = {
  name: '',
  rule: null,
  errors: null,
  register: null,
  error: false,
  customStyle: '',
  customOptionStyle: '',
};

export default dropdown;
