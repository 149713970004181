import React from 'react';
import { Moment } from 'moment';
import { find } from 'lodash';
import { LessonDay } from '../../types/lessonDay';
import capitalize from '../../helpers/utils';
import { AFTERNOON, MORNING, NOON } from '../../constants/constants';
import { getTimeSlot } from '../../helpers/lessonDay';

interface Props {
  selected: Array<LessonDay>;
  onSelect: (lessonDay: LessonDay) => void;
  onUnselect: (lessonDay: LessonDay) => void;
  day: Moment;
  lessonDays: Array<LessonDay>;
  nbLessons: number;

}

const LessonCalendarOption = ({
  selected,
  onSelect,
  onUnselect,
  day,
  lessonDays,
  nbLessons,
}: Props): JSX.Element => {
  const isSelected = (lessonDay: LessonDay): boolean => !!find(
    selected,
    (s) => s === lessonDay,
  );

  const selectDate = (lessonDay: LessonDay): void => {
    if (!isSelected(lessonDay)) {
      onSelect(lessonDay);
    } else {
      onUnselect(lessonDay);
    }
  };

  const displayCalendarOption = (isDateSelected: boolean, lessonDay: LessonDay) => {
    let className = '';
    if (isDateSelected) {
      className = 'selected';
    } else if (selected.length === nbLessons) {
      className = 'disabled';
    }
    return (
      <div
        key={`lessonCalendarOptions${lessonDay.id}`}
        className={`lesson-calendar-time ${className}`}
        onClick={(): void => selectDate(lessonDay)}
        role="button"
        tabIndex={0}
      >
        {`${lessonDay.start.substring(0, 5)
          .replace(/^0+/, '')} - ${lessonDay.end.substring(0, 5)
          .replace(/^0+/, '')}`}
      </div>
    );
  };

  const renderLine = (name: string): JSX.Element[] => lessonDays
    .filter((lessonDay: LessonDay) => getTimeSlot(lessonDay) === name)
    .map((lessonDay: LessonDay) => {
      const isDateSelected = isSelected(lessonDay);
      return displayCalendarOption(isDateSelected, lessonDay);
    });

  return (
    <div className="lesson-calendar-option-wrapper">
      <div className="lesson-calendar-header">{capitalize(day.format('ddd D MMM'))}</div>
      <div className="lesson-calendar-lines-wrapper">
        <div className="lesson-calendar-line">
          <div className="lesson-calendar-cell">
            {renderLine(MORNING)}
          </div>
        </div>
        <div className="lesson-calendar-line">
          <div className="lesson-calendar-cell">
            {renderLine(NOON)}
          </div>
        </div>
        <div className="lesson-calendar-line">
          <div className="lesson-calendar-cell">
            {renderLine(AFTERNOON)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonCalendarOption;
