import React from 'react';
import './lessonRecapData.scss';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';

interface Props {
  text: string;
  onClick: () => void;
}

export default ({ text, onClick }: Props): JSX.Element => (
  <div role="button" tabIndex={0} onClick={(): void => onClick()} className="lesson-recap-data">
    {text}
    <Edit title="edit" />
  </div>
);
