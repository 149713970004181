import React, { useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { Tic } from '../..';
import LessonDropdown from '../../lessonDropdown/lessonDropdown';
import NbPeopleSelector from '../../nbPeopleSelector/nbPeopleSelector';
import ActivitySelector from '../../activitySelector/activitySelector';
import SellerAgeSelector from '../ageSelector/ageSelector';
import './style.scss';
import { removeLesson, updateLesson } from '../../../services/lessons/lessonsSlice';
import { Lesson } from '../../../types/lesson';

interface Props {
  lesson: Lesson;
  showError: boolean;
}

export default ({ lesson, showError }: Props): JSX.Element => {
  const [firstChoiceDone, setFirstChoiceDone] = useState((lesson.isPrivate || lesson.activity !== ''));
  const dispatch = useAppDispatch();

  const onPrivacyChange = (value: boolean): void => {
    setFirstChoiceDone(true);
    dispatch(updateLesson({ ...lesson, ...{ isPrivate: value } }));
  };

  const onDelete = (): void => {
    dispatch(removeLesson(lesson));
  };

  return (
    <>
      <div className="lesson-line">
        <Tic />
        Type de cours
        <LessonDropdown
          placeholder="un cours privé ou collectif ?"
          selected={lesson}
          isEmpty={!firstChoiceDone}
          onChange={onPrivacyChange}
          error={showError && !firstChoiceDone}
        />
        {firstChoiceDone && (lesson.isPrivate
          ? <NbPeopleSelector lesson={lesson} showError={showError} />
          : (
            <>
              <ActivitySelector lesson={lesson} showError={showError} />
              <SellerAgeSelector lesson={lesson} showError={showError} />
            </>
          )
        )}
        <div role="button" tabIndex={0} className="lesson-delete" onClick={(): void => onDelete()}>x</div>
      </div>
    </>
  );
};
