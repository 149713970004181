import React, { useCallback, useState } from 'react';
import moment from 'moment';

import { InstructorLine, LessonCalendar, RadioGroup } from '..';

import { classLengthOptions, nbLessonOptions } from '../../constants/tunnelOptions';

import './privateLessonBlock.scss';
import { Lesson } from '../../types/lesson';
import { Instructor } from '../../types/instructor';
import { Product } from '../../types/product';
import { LessonDay } from '../../types/lessonDay';
import { getUniqueInstructorListFromProducts, isProductOfClassLength } from '../../helpers/products';
import { useAppDispatch } from '../../hooks';
import { removeLesson, updateLesson } from '../../services/lessons/lessonsSlice';
import { getLessonDayClassLength } from '../../helpers/lessonDay';
import Button from '../button/button';

interface Props {
  lesson: Lesson;
  products: Array<Product>
  validation?: boolean;
}

const PrivateLessonBlock = ({
  lesson,
  validation,
  products,
}: Props): JSX.Element => {
  const [availableLessonDays, setAvailableLessonDays] = useState<Array<LessonDay>>(products.flatMap(
    (product) => product.days,
  ));
  const [availableInstructors, setAvailableInstructors] = useState<Array<Instructor>>(
    getUniqueInstructorListFromProducts(products),
  );

  const dispatch = useAppDispatch();

  const onClassLengthUpdate = useCallback((value: number): void => {
    // finding all possibilties for given classlength
    const possibleProducts = products.filter((product) => isProductOfClassLength(product, value));
    if (possibleProducts) {
      // initializing timeslots for each days
      setAvailableLessonDays(possibleProducts.flatMap((product) => product.days));
      // initializing instructors
      setAvailableInstructors(getUniqueInstructorListFromProducts(possibleProducts));
      // initializing timeslots
      dispatch(updateLesson({
        id: lesson.id,
        classLength: value,
        lessonDays: [],
      }));
    } else {
      dispatch(updateLesson({
        id: lesson.id,
        classLength: value,
      }));
    }
  }, [setAvailableLessonDays, products]);

  const calculatePrice = (selectedLessonDays: LessonDay[]) => selectedLessonDays
    .map(
      (day) => {
        const product = products.find((p) => p.id === day.lesson);
        if (product) return parseFloat(product.price.incl_tax);
        return 0;
      },
    )
    .reduce(((previousValue, currentValue) => previousValue + currentValue), 0);

  const onUnselectLessonDay = useCallback((lessonDay: LessonDay): void => {
    // removing selected timeslot, I assume that there is only one
    const newLessonDays = lesson.lessonDays.filter((t) => (t !== lessonDay));
    // retrieve possible products for currently selected class length
    const possibleProducts = products.filter(
      (product) => isProductOfClassLength(product, lesson.classLength),
    );
    // if any products are available update available lessons days and Instructors
    if (possibleProducts) {
      setAvailableLessonDays(possibleProducts.flatMap((product) => product.days));
      setAvailableInstructors(getUniqueInstructorListFromProducts(possibleProducts));
    }
    const price = calculatePrice(newLessonDays);
    dispatch(updateLesson({
      id: lesson.id,
      lessonDays: newLessonDays,
      price,
    }));
  }, [lesson, products, setAvailableLessonDays, setAvailableInstructors]);

  const onSelectLessonDay = useCallback((lessonDay: LessonDay): void => {
    if (lesson.nbLessons > lesson.lessonDays.length) {
      // add a lesson day to the ones already selected
      const selectedLessonDays = [...lesson.lessonDays, lessonDay];
      const price = calculatePrice(selectedLessonDays);
      dispatch(updateLesson({
        id: lesson.id,
        lessonDays: selectedLessonDays,
        price,
      }));
    }
  }, [availableLessonDays, setAvailableLessonDays, availableInstructors, setAvailableInstructors,
    lesson.nbLessons, lesson.lessonDays]);

  const displayInstructorLine = () => {
    // console.log(validation, availableLessonDays);
    if (lesson.classLength !== 0 && availableInstructors.length) {
      return <InstructorLine instructors={availableInstructors} />;
    }
    if (validation) {
      return (
        <div className="error-text">
          Choisissez parmis les créneaux disponibles
        </div>
      );
    }
    if (availableLessonDays.length === 0) {
      return (
        <div className="error-text">
          {`Il n'y a pas de creneaux de ${moment.utc(lesson.classLength * 3600 * 1000)
            .format('H[h]mm')} disponible sur ${lesson.nbLessons} jours`}
        </div>
      );
    }
    return 'Choisissez parmis les créneaux disponibles';
  };

  return (
    <>
      {products.length === 0
        ? (
          <div className="error-text">
            <strong>Désolé!</strong>
            {' '}
            il n&apos;y a plus de disponibilité en cours privé sur les dates que vous
            avez
            séléctioné.
            <br />
            <br />
            <Button
              className="white-button"
              label="Supprimer"
              onClick={() => dispatch(removeLesson(lesson))}
            />
          </div>
        ) : (
          <>
            <div className="lesson-block-line">
              Nombre de jours de cours souhaités
            </div>
            <RadioGroup
              selected={lesson.nbLessons}
              options={nbLessonOptions}
              onClick={(value): void => {
                if (typeof value === 'number') {
                  dispatch(updateLesson({
                    id: lesson.id,
                    nbLessons: value,
                  }));
                }
              }}
              validation={validation}
            />
            {lesson.nbLessons !== 0 && (
            <div className="private-lesson-block-line">
              Durée de cours souhaitée par jour
              <div className="private-lesson-block-radio">
                <RadioGroup
                  selected={lesson.classLength}
                  options={classLengthOptions}
                  onClick={(value): void => {
                    if (typeof value === 'number') onClassLengthUpdate(value);
                    if (typeof value === 'string') {
                      onClassLengthUpdate(moment.duration(value)
                        .hours());
                    }
                  }}
                  scrollOnMobile
                  disabled={classLengthOptions.map((length) => length.value)
                    .filter((length) => !Array.from(
                      new Set(products.flatMap((p) => p.days)
                        .map((lessonDay) => getLessonDayClassLength(lessonDay))),
                    )
                      .includes(length))}
                  validation={validation}
                />
              </div>
            </div>
            )}

            <div className="private-lesson-block-line">
              {!!lesson.classLength && (
                <>
                  {displayInstructorLine()}
                  {availableLessonDays.length !== 0 && (
                  <LessonCalendar
                    nbLessons={lesson.nbLessons}
                    lessonDays={availableLessonDays}
                    selected={lesson.lessonDays}
                    onSelect={(lessonDay): void => onSelectLessonDay(lessonDay)}
                    onUnselect={(lessonDay): void => onUnselectLessonDay(lessonDay)}
                  />
                  )}
                </>
              )}
            </div>
          </>
        )}

    </>
  );
};

PrivateLessonBlock.defaultProps = {
  validation: null,
};
export default PrivateLessonBlock;
