import React, { useState } from 'react';

import '../paymentForm/paymentForm.scss';
import { Button, Input } from '../index';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser, updateUser } from '../../services/users/userSlice';
import { post } from '../../api/api';

interface Props {
  hasBeenSubmit: boolean;
}

export default ({
  hasBeenSubmit,
}: Props): JSX.Element => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const signUpUser = () => {
    post('/client/',
      {
        username: user.email,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        phone: user.phone,
      },
      () => {
        dispatch(updateUser({ isLoggedIn: true }));
        setErrorMessage('');
      },
      () => {
        dispatch(updateUser({ isLoggedIn: false }));
        setErrorMessage('Cet email est déjà enregistré, veuillez vous connectez à votre compte.');
      });
  };
  return (
    <>
      <div className="tunnel-informations-line">
        <Input
          type="text"
          placeholder="Votre prénom"
          value={user.firstName}
          onChange={(firstName) => dispatch(updateUser({ firstName }))}
          extraClass={(hasBeenSubmit && !user.firstName)
            ? 'payment-form-container-error' : ''}
        />
        <Input
          type="text"
          placeholder="Votre nom"
          value={user.lastName}
          onChange={(lastName) => dispatch(updateUser({ lastName }))}
          extraClass={(hasBeenSubmit && !user.lastName)
            ? 'payment-form-container-error' : ''}
        />
      </div>
      <div className="tunnel-informations-line">
        <Input
          type="text"
          placeholder="Votre adresse email"
          value={user.email}
          onChange={(email) => dispatch(updateUser({ email }))}
          extraClass={(hasBeenSubmit && !user.email)
            ? 'payment-form-container-error' : ''}
        />
        <Input
          type="tel"
          placeholder="Votre numéro de téléphone"
          value={user.phone}
          onChange={(phone) => dispatch(updateUser({ phone }))}
          extraClass={(hasBeenSubmit && !user.phone)
            ? 'payment-form-container-error' : ''}
        />
      </div>

      <Button
        label="Valider mes informations"
        className="white-button"
        onClick={signUpUser}
      />
      <span className="error-message-tunnel-info">{errorMessage}</span>
    </>
  );
};
