import React, { useState, useRef, useEffect } from 'react';

import './style.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { sumBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  Button, Dropdown, LessonLine, Tic, Title,
} from '..';
import { Lesson } from '../../types/lesson';
import { peopleDropdownOptions } from '../../constants/tunnelOptions';
import { newLesson } from '../../helpers/lesson';
import { addLesson, selectLessons } from '../../services/lessons/lessonsSlice';

interface Props {
  focus: number | null;
  error: string;
}

export default ({ error, focus }: Props): JSX.Element => {
  const lessons = useAppSelector(selectLessons);

  const checkTotalPeople = (): number => sumBy(lessons, (l) => (l.isPrivate ? l.nbPeople : 1));

  const [nbPeople, setNbPeople] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (scrollRef.current) {
      window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop });
    }
  }, []);

  const checkLineComplete = (lesson: Lesson): boolean => (
    (lesson.isPrivate && lesson.nbPeople !== 0) || (!lesson.isPrivate && lesson.age !== '')
  );

  const addNewLine = (): void => {
    dispatch(addLesson(newLesson(lessons.length)));
  };

  // Add a new line at start or on line complete if new line is required
  // Delete a line if the total number of people exceed the total number mentioned at start
  useEffect(() => {
    const totalPeople = checkTotalPeople();
    if (nbPeople > 0
        && ((totalPeople < nbPeople && lessons.length >= 1
            && checkLineComplete(lessons[lessons.length - 1]))
            || lessons.length === 0)) addNewLine();
  }, [lessons, nbPeople]);

  return (
    <div className="lesson-selection-container">
      <Title icon="😎" label="C'est parti, dites-nous en un peu plus sur votre réservation !" />
      <div className="lesson-selection-number-selector">
        <Tic />
        Vous souhaitez prendre un cours pour
        <Dropdown
          placeholder="combien de personnes ?"
          value={nbPeople}
          options={peopleDropdownOptions}
          onChange={(value): void => setNbPeople(value)}
          error={!!error && !nbPeople}
        />
      </div>
      {lessons.map((lesson, index) => (
        <div key={`LessonLine${lesson.id}`}>
          {focus === index && <div ref={scrollRef} />}
          <LessonLine
            lesson={lesson}
            showError={!!error}
          />
        </div>
      ))}

      {lessons.length > 0
      && checkTotalPeople() >= nbPeople
      && checkLineComplete(lessons[lessons.length - 1]) && (
        <Button
          label="+ ajouter 1 cours"
          className="white-button lesson-selection-add"
          onClick={(): void => addNewLine()}
        />
      )}

    </div>
  );
};
