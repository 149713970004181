import React, { useEffect, useState } from 'react';

import './style.scss';

import { sumBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Button, Dropdown, Tic } from '../..';
import SellerLessonLine from '../lessonLine/lessonLine';
import SellerLessonDetails from '../lessonDetails/lessonDetails';
import { Lesson } from '../../../types/lesson';
import { peopleDropdownOptions } from '../../../constants/tunnelOptions';
import { newLesson } from '../../../helpers/lesson';
import { addLesson, selectLessons } from '../../../services/lessons/lessonsSlice';

interface Props {
  error: string;
}

export default ({ error }: Props): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const [nbPeople, setNbPeople] = useState(0);
  const dispatch = useAppDispatch();

  const addNewLine = (): void => {
    dispatch(addLesson(newLesson(lessons.length)));
  };

  const checkLineComplete = (lesson: Lesson): boolean => (lesson.isPrivate && lesson.nbPeople !== 0)
      || (!lesson.isPrivate && lesson.age !== '');

  const checkTotalPeople = (): number => sumBy(lessons, (l) => (l.isPrivate ? l.nbPeople : 1));

  // Add a new line at start or on line complete if new line is required
  // Delete a line if the total number of people exceed the total number mentioned at start
  useEffect(() => {
    const totalPeople = checkTotalPeople();
    if (nbPeople > 0
        && ((totalPeople < nbPeople
            && lessons.length >= 1
            && checkLineComplete(lessons[lessons.length - 1]))
            || lessons.length === 0)) addNewLine();
    // eslint-disable-next-line
    }, [lessons, nbPeople])

  return (
    <div className="seller-lesson-selection-container">
      <div className="seller-lesson-selection-number-selector">
        <Tic />
        pour
        <Dropdown
          placeholder="combien de personnes ?"
          value={nbPeople}
          options={peopleDropdownOptions}
          onChange={(value): void => setNbPeople(value)}
          error={!!error && !nbPeople}
        />
      </div>
      <hr />
      {lessons.map((lesson: Lesson, index: number) => (
        index === 0 || checkLineComplete(lessons[index - 1])
      ) && (
        <div key={`Lesson ${lesson.id}`}>
          <div className="seller-lesson-lesson-index no-tic">
            Cours
            {' '}
            {index + 1}
          </div>
          <SellerLessonLine
            lesson={lesson}
            showError={!!error}
          />
          {
                        checkLineComplete(lesson)
                        && <SellerLessonDetails index={index} />
                    }
          <hr />
        </div>
      ))}

      {lessons.length > 0
      && checkTotalPeople() >= nbPeople
      && checkLineComplete(lessons[lessons.length - 1]) && (
        <Button
          label="+ ajouter 1 cours"
          className="white-button seller-lesson-selection-add"
          onClick={(): void => setNbPeople(nbPeople + 1)}
        />
      )}

    </div>
  );
};
