import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  setReservationDateFrom,
  setReservationDateTo,
  setReservationSelectedStation,
} from '../../services/reservation/reservationSlice';
import { addLesson } from '../../services/lessons/lessonsSlice';

type Props = RouteComponentProps;

export default withRouter((props: Props): JSX.Element => {
  const reservationSelector = useAppSelector((state: any) => state.reservation);
  const [redirect, setRedirect] = useState<string>('');
  const dispatch = useAppDispatch();
  const firstUpdate = useRef(true);

  useEffect(() => {
    const parse = qs.parse(props.location.search);
    if (typeof parse.station_slug === 'string' && typeof parse.station === 'string' && typeof parse.station_id === 'string') {
      dispatch(setReservationSelectedStation({
        slug: parse.station_slug,
        name: parse.station,
        id: parseInt(parse.station_id, 10),
      }));
      if (typeof parse.checkin === 'string') dispatch(setReservationDateFrom(parse.checkin));
      if (typeof parse.checkout === 'string') dispatch(setReservationDateTo(parse.checkout));
    }

    if (parse.lesson_type || parse.lesson_activity || parse.lesson_age_range) {
      dispatch(addLesson({
        id: 0,
        isPrivate: parse.lesson_type === 'private',
        activity: typeof parse.lesson_activity === 'string' ? parse.lesson_activity : '',
        age: typeof parse.lesson_age_range === 'string' ? parse.lesson_age_range : '',
        nbPeople: 0,
        levelUncertain: false,
        timeslot: null,
        nbDays: 6,
        startDay: null,
        level: 0,
        nbLessons: 0,
        classLength: 0,
        lessonDays: [],
        pass: false,
        price: 0,
      }));
    }
  }, [dispatch, props.location.search]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (reservationSelector.station) {
      if (reservationSelector.dateFrom && reservationSelector.dateTo) setRedirect('/reservation/lessons');
      else setRedirect('/reservation/dates');
    } else setRedirect('/reservation/station');
  }, [reservationSelector]);

  return (
    <div>
      {redirect && <Redirect to={redirect} />}
    </div>
  );
});
