import React from 'react';

import './style.scss';

interface Props {
  label: string;
  className: string;
  onClick: (ev: any) => void;
  disabled?: boolean;
}

const button = ({
  label, className, onClick, disabled,
}: Props): JSX.Element => (
  <button
    className={`skifamily-button ${className}`}
    onClick={onClick}
    disabled={disabled}
    type="button"
  >
    {label}
  </button>
);

button.defaultProps = {
  disabled: false,
};

export default button;
