import React from 'react';

import './orderDetails.scss';

interface Props {
  orderId: number;
  orderDate: string;
  customerEmail: string;
  orderTotal: number;
  orderPaymentMethod: string;
  title: string;
}

export default ({
  orderId, orderDate, customerEmail, orderTotal, orderPaymentMethod, title,
}: Props): JSX.Element => (
  <div className="order-details">
    <h2 className="order-details-title">{title}</h2>
    <div className="order-details-box">
      <div className="order-details-box-label">Numéro de commande :</div>
      <div className="order-details-box-value">{orderId}</div>
      <hr />
      <div className="order-details-box-label">Date :</div>
      <div className="order-details-box-value">{orderDate}</div>
      <hr />
      <div className="order-details-box-label">E-mail :</div>
      <div className="order-details-box-value">{customerEmail}</div>
      <hr />
      <div className="order-details-box-label">Total :</div>
      <div className="order-details-box-value">
        {orderTotal}
        {' '}
        €
      </div>
      <hr />
      <div className="order-details-box-label">Moyen de paiement :</div>
      <div className="order-details-box-value">{orderPaymentMethod}</div>
    </div>
  </div>
);
