import React, { useEffect, useRef } from 'react';

import './style.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { find } from 'lodash';
import moment from 'moment';
import { useAppSelector } from '../../hooks';
import { Tic, Title } from '..';
import PrivateLessonBlock from '../privateLessonBlock/privateLessonBlock';
import PublicLessonBlock from '../publicLessonBlock/publicLessonBlock';
import DealBlock from '../dealblock/dealblock';

import { Deal } from '../../types/deal';
import {
  dealAfternoon,
  dealChild,
  dealCollective,
} from '../../constants/tunnelOptions';

import { Lesson } from '../../types/lesson';
import { selectLessons } from '../../services/lessons/lessonsSlice';
import {
  selectArrivalDate, selectDepartureDate,
  selectReservationFocus,
} from '../../services/reservation/reservationSlice';
import { selectProducts } from '../../services/products/productsSlice';
import { getProductActivity, getProductAge, isProductPrivateLesson } from '../../helpers/products';
import {
  ADULTS, ALL_AGES, CHILDREN, MORNING,
} from '../../constants/constants';
import { LessonDay } from '../../types/lessonDay';

interface Props {
  validation: boolean;
}

export default ({ validation }: Props): JSX.Element => {
  const lessons = useAppSelector(selectLessons);
  const products = useAppSelector(selectProducts);
  const focus = useAppSelector(selectReservationFocus);
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: scrollRef.current.offsetTop,
      });
    }
  }, []);

  const renderTitle = (lesson: Lesson): string => (
    lesson.isPrivate ? 'Cours Privé' : `Cours Collectif - ${lesson.age === ADULTS ? 'Adultes' : 'Enfants'}`
  );

  const getDeals = (lesson: Lesson): Array<Deal> => {
    const deals: Array<Deal> = [];
    if (!lesson.isPrivate) {
      if (lesson.timeslot !== MORNING) {
        deals.push({
          label: dealAfternoon,
          tags: [],
        });
      }
      if (lesson.age === CHILDREN) {
        deals.push({
          label: dealChild,
          tags: [],
        });
      }
      deals.push({
        label: dealCollective,
        tags: [],
      });
    }
    return deals;
  };

  return (
    <div ref={focus === null ? scrollRef : null} className="lesson-detail">
      <Title icon="🗓" label="Trouvez les horaires qui colleront à votre rythme !" />
      <div className="lesson-detail-container">
        {products !== null && lessons.map((lesson: Lesson, index: number) => (
          <div
            key={`LessonDetail${lesson.id}`}
            ref={focus === index ? scrollRef : null}
            className="lesson-block-container"
          >
            <div className="lesson-block-detail">
              <div className="lesson-block-title">
                <Tic />
                {renderTitle(lesson)}
              </div>
              {lesson.isPrivate
                ? (
                  <PrivateLessonBlock
                    lesson={lesson}
                    products={products.filter((product) => isProductPrivateLesson(product)
                        && product.days.reduce(
                          (previousValue:boolean, nextValue:LessonDay) => previousValue
                            && (moment(nextValue.date).isSameOrAfter(dateFrom))
                            && (moment(nextValue.date).isSameOrBefore(dateTo)), true,
                        ))}
                    validation={validation}
                    key={`PrivateLessonBlock-${lesson.id}`}
                  />
                )
                : (
                  <PublicLessonBlock
                    products={products.filter((product) => !isProductPrivateLesson(product)
                        && getProductActivity(product) === lesson.activity
                        && (getProductAge(product) === ALL_AGES
                            || getProductAge(product) === lesson.age))}
                    lesson={lesson}
                    validation={validation}
                    key={`PublicLessonBlock-${lesson.id}`}
                  />
                )}
            </div>
            <DealBlock
              title={`Bon plan cours ${lesson.isPrivate ? 'privé' : 'collectif'}`}
              deals={getDeals(lesson)}
              lesson={lesson}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
