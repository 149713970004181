import React, { useState } from 'react';

import './paymentForm.scss';
import { useAppSelector } from '../../hooks';
import { Checkout, UserForm } from '../index';
import { selectUser } from '../../services/users/userSlice';

interface Props {
  setRedirect: (url: string) => void;
  setPaymentError:(error:string)=>void;
}

export default ({
  setRedirect, setPaymentError,
}: Props): JSX.Element => {
  const user = useAppSelector(selectUser);
  const [hasBeenSubmit, setHasBeenSubmit] = useState(false);
  const [waitingForValidation, setWaitingForValidation] = useState(false);

  return (
    <form className="tunnel-informations-form">
      <UserForm
        hasBeenSubmit={hasBeenSubmit}
      />
      {user.isLoggedIn && (
      <Checkout
        hasBeenSubmit={hasBeenSubmit}
        setHasBeenSubmit={setHasBeenSubmit}
        waitingForValidation={waitingForValidation}
        setWaitingForValidation={setWaitingForValidation}
        setRedirect={setRedirect}
        setPaymentError={setPaymentError}
      />
      )}

    </form>
  );
};
