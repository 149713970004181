import {
  AFTERNOON, MONDAY, MORNING, SKI, SNOWBOARD, SNOWSHOEING, SUNDAY,
} from './constants';

export const peopleDropdownOptions = [
  { label: '1 personne', option: 1 },
  { label: '2 personnes', option: 2 },
  { label: '3 personnes', option: 3 },
  { label: '4 personnes', option: 4 },
  { label: '5 personnes', option: 5 },
  { label: '6 personnes', option: 6 },
];

export const privacyDropdownOptions = [
  {
    label: 'un Cours collectif', title: 'Cours collectif', value: false, description: ['1 semaine de 5 ou 6 jours', 'En petits groupes de 4 à 8 élèves', 'Répartis par niveaux'],
  },
  {
    label: 'un Cours privé', title: 'Cours privé', value: true, description: ['Un moniteur rien que pour vous', "Seul ou avec vos proches jusqu'à 5 personnes"],
  },
];

export const lessonDayTimeOptions = [
  { label: 'Matin', value: MORNING },
  { label: 'Après-midi', value: AFTERNOON },
];

export const lessonNumberOfDaysOptions = [
  { label: '5 jours', value: 5 },
  { label: '6 jours', value: 6 },
];

export const activityDropdonwOptions = [
  { label: 'apprendre à skier', option: SKI },
  { label: 'apprendre le snow', option: SNOWBOARD },
  { label: 'une balade en raquettes', option: SNOWSHOEING },
];

export const ageDropdownOptions = [
  { label: '4 à 8 ans', option: 1 },
  { label: '9 à 12 ans', option: 2 },
  { label: '13 à 17 ans', option: 3 },
  { label: 'Adultes', option: 4 },
];

export const nbPeopleDropdownOptions = [
  { label: '1 personne', option: 1 },
  { label: '2 personnes', option: 2 },
  { label: '3 personnes', option: 3 },
  { label: '4 personnes', option: 4 },
  { label: '5 personnes', option: 5 },
];

export const nbLessonOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
];

export const startingDayOptions = [
  { label: 'A partir du dimanche', value: SUNDAY },
  { label: 'A partir du lundi', value: MONDAY },
];

export const mobileStartingDayOptions = [
  { label: 'Dimanche', value: SUNDAY },
  { label: 'Lundi', value: MONDAY },
];

export const classLengthOptions = [
  { label: '1h', value: 1 },
  { label: '1h30', value: 1.5 },
  { label: '2h', value: 2 },
  { label: '2h30', value: 2.5 },
  { label: '3h', value: 3 },
  { label: '4h', value: 3.5 },
  { label: '5h', value: 5 },
  { label: '6h', value: 6 },
];

export const levels = [
  {
    value: 0, color: 'white', label: 'Débutant', description: "C'est la première fois sur des skis !",
  },
  {
    value: 1, color: 'yellow', label: 'Bonhomme de neige', description: ' je sais chausser les skis et je me déplace sur pente douce',
  },
  {
    value: 2, color: 'green', label: 'Cristal', description: "j'enchaîne les virages en chasse-neige sur piste verte",
  },
  {
    value: 3, color: 'blue', label: 'Bronze', description: 'je commence les virages parallèles et je descends des pistes bleues',
  },
  {
    value: 4, color: 'red', label: 'Argent', description: "j'enchaîne les virages parallèles et je commence à descendre des pistes rouges",
  },
  {
    value: 5, color: 'black', label: 'Vermeil', description: 'Je skie parallèle sur pistes rouge et noire et je souhaite améliorer ma technique',
  },
];

export const dealAfternoon = "Le prix des cours collectifs est moins élevé l'après-midi !";
export const dealChild = 'A la fin de la semaine, la médaille de niveau officielle ESI est déscernée par votre moniteur (inclus dans le prix).';
export const dealCollective = 'De 4 à 8 élèves';
