import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lesson, Lessons } from '../../types/lesson';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';

/* eslint-disable no-param-reassign */

const initialState: Lessons = {
  lessons: [],
};
export const lessonsSlice = createSlice(
  {
    name: 'lessons',
    initialState,
    reducers: {
      addLesson: (state, action:PayloadAction<Lesson|Lesson[]>) => {
        state.lessons = state.lessons.concat(action.payload);
      },
      removeLesson: (state, action:PayloadAction<Lesson>) => {
        state.lessons = state.lessons.filter((lesson) => lesson.id !== action.payload.id);
      },
      updateLesson: (state, action:PayloadAction<Partial<Lesson>>) => {
        state.lessons = state.lessons.map((lesson) => (
          lesson.id === action.payload.id ? { ...lesson, ...action.payload } : lesson));
      },
    },
  },
);

export const { addLesson, removeLesson, updateLesson } = lessonsSlice.actions;

export const selectLessons = (state:RootState) => state.lessons.lessons;

export default lessonsSlice.reducer;
