import React, { useState } from 'react';
import {
  Checkbox, IsNotYetUserForm, IsUserForm, Title,
} from '../index';
import { useAppSelector } from '../../hooks';
import { selectUser } from '../../services/users/userSlice';

interface Props {
  hasBeenSubmit: boolean;
}

const UserForm = ({
  hasBeenSubmit,
}: Props): JSX.Element => {
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const user = useAppSelector(selectUser);

  const displayUserForm = (): JSX.Element => {
    if (user.isLoggedIn) {
      return (
        <>
          <div className="tunnel-informations-text">
            <strong>Parfait !</strong>
            <br />
            Bienvenue
            {' '}
            {user.firstName}
            .
          </div>
        </>
      );
    }
    return hasAccount ? (
      <IsUserForm
        hasBeenSubmit={hasBeenSubmit}
      />
    ) : (
      <IsNotYetUserForm
        hasBeenSubmit={hasBeenSubmit}
      />
    );
  };

  return (
    <>
      <Title icon="📋" label="Création de votre compte" />

      <div className="tunnel-informations-user-form">
        <div className="tunnel-informations-checkbox-container">
          <Checkbox value={hasAccount} onClick={(v: boolean): void => setHasAccount(v)}>
            <div className="tunnel-informations-checkbox-has-account">
              J&apos;ai déjà un
              compte
            </div>
          </Checkbox>
        </div>
        {displayUserForm()}
      </div>
    </>
  );
};

export default UserForm;
