import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';
import './style.scss';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { SellerDateSelection, SellerLessonSelection, SellerStationSelection } from '../../components';
import { getTunnelInfo } from '../../api';
import { setProducts } from '../../services/products/productsSlice';
import { get } from '../../api/api';
import { updateStations } from '../../services/stations/stationsSlice';
import {
  selectArrivalDate,
  selectDepartureDate,
  selectSelectedStation,
} from '../../services/reservation/reservationSlice';
import { Station } from '../../types/station';

export default (): JSX.Element => {
  const [redirect, setRedirect] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const selectedStation = useAppSelector(selectSelectedStation);
  const dispatch = useAppDispatch();

  const [error] = useState('');

  const userHasPermission = (): void => {
    get('/permission/',
      (res) => {
        if (!res.data.permission) {
          localStorage.removeItem('api-token');
          localStorage.removeItem('api-refresh');
        }
        setRedirect(!res.data.permission);
        setAllowed(res.data.permission);
      },
      () => {
        localStorage.removeItem('api-token');
        localStorage.removeItem('api-refresh');
        setRedirect(true);
      });
  };

  useEffect(() => {
    userHasPermission();
    get('/locations/',
      (res) => {
        res.data.forEach((value: Station) => {
          dispatch(updateStations([value]));
        });
      },
      () => null);
  }, []);

  useEffect(() => {
    if (selectedStation.slug && dateFrom && dateTo) {
      getTunnelInfo(
        {
          station: selectedStation,
          date: {
            arrival: dateFrom,
            departure: dateTo,
          },
        },
        (res) => {
          dispatch(setProducts(res.data.products));
        },
        () => null,
      );
    }
  }, [dateFrom, dateTo, selectedStation, dispatch]);

  return (
    <>
      {redirect && <Redirect to="/reservation/login" />}
      {allowed
            && (
            <div className="seller-interface">
              <SellerStationSelection />
              <SellerLessonSelection error={error} />
              <SellerDateSelection />
            </div>
            )}
    </>
  );
};
