import React, { useEffect, useState } from 'react';

import './studentsAndReferentsForm.scss';
import { useAppSelector } from '../../hooks';
import ReferentForm from '../referentForm/referentForm';
import { StudentForm } from '../index';
import { selectLessons } from '../../services/lessons/lessonsSlice';

interface Props {
  id: number;
  genders: Array<{ id: number; name: string }>;
  languages: Array<{ id: number; name: string }>;
}

export default (
  { id, genders, languages }: Props,
): JSX.Element => {
  const lessons = useAppSelector(selectLessons);

  const [studentForms, setStudentForms] = useState<Array<JSX.Element>>([]);

  useEffect(() => {
    const getNbStudents = (index: number): number => (
      lessons[index].isPrivate ? lessons[index].nbPeople : 1
    );

    const forms = [];
    let nbStudentsDisplayed = 0;
    for (let i = 0; i < id; i += 1) {
      nbStudentsDisplayed += getNbStudents(i);
    }

    for (let i = 0; i < getNbStudents(id); i += 1) {
      forms.push(
        <StudentForm
          key={`StudentForm${nbStudentsDisplayed + i}`}
          id={nbStudentsDisplayed + i}
          referentId={id}
          genders={genders}
          languagesOptions={languages}
        />,
      );
    }
    setStudentForms(forms);
  }, [genders, languages, id, lessons]);

  const lesson = `Cours ${id + 1}`;
  const type = lessons[id].isPrivate ? 'Cours privée' : 'Cours collectif';
  const { activity } = lessons[id];

  return (
    <div className="stud-ref-form-container">
      <h2>
        {lesson}
        {' '}
        -
        {' '}
        {type}
        {' '}
        -
        {' '}
        {activity}
      </h2>
      {studentForms}
      <ReferentForm
        id={id}
      />
    </div>
  );
};
