import React, { useEffect, useState } from 'react';

import './publicLessonBlock.scss';

import { Lesson } from '../../types/lesson';
import { Checkbox, LevelSelector, RadioGroup } from '..';
import {
  lessonDayTimeOptions,
  levels,
  mobileStartingDayOptions,
  startingDayOptions,
} from '../../constants/tunnelOptions';

import { updateLesson } from '../../services/lessons/lessonsSlice';
import { useAppDispatch } from '../../hooks';
import { Product } from '../../types/product';
import { MORNING, SUNDAY } from '../../constants/constants';
import { getProductTimeSlot } from '../../helpers/products';

interface Props {
  lesson: Lesson;
  products: Array<Product>;
  validation?: boolean;
}

const PublicLessonBlock = ({
  lesson,
  products,
  validation,
}: Props): JSX.Element => {
  const {
    label,
    description,
  } = levels[lesson.level];
  const dispatch = useAppDispatch();
  const [timeslot, setTimeslot] = useState(lesson.timeslot);
  const [startDay, setStartDay] = useState(lesson.startDay);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  useEffect(() => {
    setSelectedProduct(products.find((p) => (getProductTimeSlot(p) === timeslot
            && (startDay === SUNDAY ? p.days.length === 6 : p.days.length === 5))));
  }, [timeslot, startDay]);

  useEffect(() => {
    const price = selectedProduct !== undefined ? parseFloat(selectedProduct.price.incl_tax) : 0;
    const lessonDays = selectedProduct !== undefined ? selectedProduct.days : [];
    dispatch(updateLesson({
      id: lesson.id,
      timeslot,
      startDay,
      price,
      lessonDays,
    }));
  }, [selectedProduct]);

  const displayFormOrError = (): JSX.Element => {
    if (products.length === 0) {
      return (
        <div className="public-lesson-warning">
          Malheureusement il n&apos;y a plus de cours collectif disponibles sur ces dates,
          changer votre séléction pour trouver un cours
        </div>
      );
    }
    return (
      <>
        <div>
          <RadioGroup
            selected={timeslot}
            options={lessonDayTimeOptions.filter((option) => products
              .map((p) => getProductTimeSlot(p))
              .includes(option.value))}
            onClick={(value): void => {
              if (typeof value === 'string') {
                setTimeslot(value);
              }
            }}
            validation={validation}
          />
          <div className="lesson-block-radio-description">
            Horaires
            :
            {lesson.timeslot === MORNING ? '9h15 - 12h' : '14h35 - 17h'}
          </div>
        </div>
        {timeslot && (
        <div>
          <div className="lesson-block-radio-mobile">
            <div className="lesson-block-radio-subtitle">A partir du</div>
            <RadioGroup
              selected={startDay}
              options={mobileStartingDayOptions}
              onClick={(value): void => {
                if (typeof value === 'string') {
                  setStartDay(value);
                }
              }}
              validation={validation}
            />
          </div>
          <div className="lesson-block-radio-desktop">
            <RadioGroup
              selected={lesson.startDay}
              options={startingDayOptions}
              onClick={(value): void => {
                if (typeof value === 'string') {
                  setStartDay(value);
                }
              }}
              validation={validation}
            />
          </div>
          <div
            className="lesson-block-radio-description"
          >
            {lesson.startDay === SUNDAY ? '6 jours : du dimanche au vendredi' : '5 jours : du lundi au vendredi'}
          </div>
        </div>
        )}
        {timeslot && startDay && selectedProduct !== undefined && (
        <>
          <div className="public-lesson-level-title">
            Indiquez le niveau de la personne concernée
            par le cours
          </div>
          <div className="public-lesson-level">
            <LevelSelector
              value={lesson.level}
              onClick={(level: number): void => {
                dispatch(updateLesson({
                  id: lesson.id,
                  level,
                }));
              }}
            />
          </div>
          <div className="public-lesson-level-name">
            Niveau:
            {label}
          </div>
          <div className="public-lesson-level-description">{description}</div>
          <Checkbox
            value={!!lesson.levelUncertain}
            onClick={(): void => {
              dispatch(updateLesson({
                id: lesson.id,
                levelUncertain: !lesson.levelUncertain,
              }));
            }}
          >
            <span className="public-lesson-level-checkbox">Pas sûr du niveau ? Sélectionnez au mieux et nous vous rappellerons pour confirmer.</span>
          </Checkbox>
        </>
        )}
        {timeslot && startDay && selectedProduct === undefined && (
        <div className="public-lesson-warning">
          Malheureusement nous n&apos;avons plus de disponibilité correspondant à cette selection
        </div>
        )}
      </>
    );
  };

  return displayFormOrError();
};

PublicLessonBlock.defaultProps = {
  validation: null,
};

export default PublicLessonBlock;
