import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { StationSelection } from '../../components';

import './style.scss';
import { get } from '../../api/api';
import { updateStations } from '../../services/stations/stationsSlice';
import { ReactComponent as ArrowRightWhite } from '../../assets/icons/arrow-right-white.svg';
import { ReactComponent as Shadow } from '../../assets/images/shadow.svg';
import { selectSelectedStation } from '../../services/reservation/reservationSlice';

export default (): JSX.Element => {
  const selectedStation = useAppSelector(selectSelectedStation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    get('/locations/',
      (res) => dispatch(updateStations(res.data)),
      () => null);
    // eslint-disable-next-line
    }, []);

  const [next, setNext] = useState(false);
  const nextStep = useCallback((): void => { setNext(true); }, [setNext]);

  return (
    <div className="scene-station">
      <div className="progress-bar-20" />
      { next && <Redirect to="/reservation/dates" />}
      <StationSelection />
      { selectedStation.name && (
        <div className="scene-station-block">
          <button type="button" onClick={(): void => nextStep()}>
            <div className="scene-station-block-bg" />
            <ArrowRightWhite title="next" />
          </button>
          <div className="scene-station-block-text">Trouvez le cours qui vous convient en 3 étapes</div>
        </div>
      )}
      <div className="scene-station-bottom">
        <div className="scene-station-footer" />
        <Shadow className="scene-station-shadow" title="shadow" />
      </div>
    </div>
  );
};
