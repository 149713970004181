import React from 'react';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { store } from './store';
import { Header } from './components';
import Router from './router';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <div className="App">
          <Header />
          <Router />
        </div>
      </Elements>
    </Provider>
  );
}

export default App;
