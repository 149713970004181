import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { Dropdown } from '..';
import './style.scss';
import { ageDropdownOptions } from '../../constants/tunnelOptions';
import { updateLesson } from '../../services/lessons/lessonsSlice';
import { ADULTS, CHILDREN } from '../../constants/constants';
import { Lesson } from '../../types/lesson';

interface Props {
  lesson: Lesson;
  showError: boolean;
}

export default ({ lesson, showError }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [age, setAge] = useState(0);

  const onAgeChange = (value: number): void => {
    setAge(value);
    if (value <= 2) {
      dispatch(updateLesson({ ...lesson, ...{ age: CHILDREN } }));
    } else {
      dispatch(updateLesson({ ...lesson, ...{ age: ADULTS } }));
    }
  };

  return (
    <div className="lesson-line no-tic">
      Pour une personne âgée de :
      <Dropdown
        placeholder="tranche d'age"
        value={age}
        options={ageDropdownOptions}
        onChange={onAgeChange}
        error={showError && !lesson.age}
      />
    </div>
  );
};
