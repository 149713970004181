import React from 'react';
import { Dropdown } from '..';
import './style.scss';
import { activityDropdonwOptions } from '../../constants/tunnelOptions';
import { updateLesson } from '../../services/lessons/lessonsSlice';
import { useAppDispatch } from '../../hooks';
import { Lesson } from '../../types/lesson';

interface Props {
  lesson: Lesson;
  showError: boolean;
}

export default ({ lesson, showError }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const onActivityChange = (value: string): void => {
    dispatch(updateLesson({ ...lesson, ...{ activity: value } }));
  };

  return (
    <>
      pour
      <Dropdown
        placeholder="type de cours"
        value={lesson.activity}
        options={activityDropdonwOptions}
        onChange={onActivityChange}
        error={showError && !lesson.activity}
      />
    </>
  );
};
