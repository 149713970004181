import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';
import { ClientSecretState } from '../../types/clientSecret';

/* eslint-disable no-param-reassign */

const initialState: ClientSecretState = {
  clientSecret: '',
  orderId: -1,
};
export const clientSecretSlice = createSlice(
  {
    name: 'clientSecret',
    initialState,
    reducers: {
      setClientSecret: (state, action:PayloadAction<ClientSecretState>) => {
        state.clientSecret = action.payload.clientSecret;
        state.orderId = action.payload.orderId;
      },
    },
  },
);

export const { setClientSecret } = clientSecretSlice.actions;

export const selectOrderId = (state:RootState) => state.clientSecret.orderId;
export const selectClientSecret = (state:RootState) => state.clientSecret.clientSecret;

export default clientSecretSlice.reducer;
