import React from 'react';
import './lesson-calendar.scss';
import moment, { Moment } from 'moment/moment';
import { LessonDay } from '../../types/lessonDay';
import { useAppSelector } from '../../hooks';
import { selectArrivalDate, selectDepartureDate } from '../../services/reservation/reservationSlice';
import LessonCalendarOption from '../lessonCalendarOption/lessonCalendarOption';

interface Props {
  onSelect: (lessonDay: LessonDay) => void;
  onUnselect: (lessonDay: LessonDay) => void;
  nbLessons: number;
  selected: Array<LessonDay>;
  lessonDays: Array<LessonDay>;
}

export default ({
  onSelect,
  onUnselect,
  selected,
  lessonDays,
  nbLessons,
}: Props): JSX.Element => {
  const dateFrom = useAppSelector(selectArrivalDate);
  const dateTo = useAppSelector(selectDepartureDate);
  const weeks = dateTo && dateFrom ? new Array<number>(dateTo.diff(dateFrom, 'days') + 1)
    .fill(0)
    .map((item, index) => dateFrom.clone()
      .add(index, 'days'))
    .reduce<Array<Array<Moment>>>((previousValue, currentValue, index) => {
    const chunkIndex = Math.floor(index / 7);
    const retVal: Array<Array<Moment>> = previousValue;
    if (!retVal[chunkIndex]) {
      retVal[chunkIndex] = []; // start a new chunk
    }
    retVal[chunkIndex].push(currentValue);
    return retVal;
  }, []) : [];

  return (
    <div className="lesson-calendar">
      {weeks.map((days) => (
        <div className="lesson-calendar-week-wrapper" key={`Week-${days[0]}-${days[days.length - 1]}`}>
          {days.map((day) => (
            <LessonCalendarOption
              selected={selected}
              onSelect={onSelect}
              onUnselect={onUnselect}
              day={day}
              lessonDays={lessonDays.filter((lessonday) => moment(lessonday.date)
                .isSame(day, 'day'))}
              nbLessons={nbLessons}
              key={`Session-${day}`}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
