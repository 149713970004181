import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/user';
import { Student } from '../../types/student';
import { Referent } from '../../types/referent';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../store';

/* eslint-disable no-param-reassign */

const initialState: User = {
  id: -1,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  acceptCGU: false,
  students: [],
  referents: [],
  isLoggedIn: false,
};
export const userSlice = createSlice(
  {
    name: 'users',
    initialState,
    reducers: {
      updateUser: (state, action:PayloadAction<Partial<User>>) => {
        if (action.payload.id) state.id = action.payload.id;
        if (action.payload.firstName) state.firstName = action.payload.firstName;
        if (action.payload.lastName) state.lastName = action.payload.lastName;
        if (action.payload.email) state.email = action.payload.email;
        if (action.payload.phone) state.phone = action.payload.phone;
        if (action.payload.acceptCGU) state.acceptCGU = action.payload.acceptCGU;
        if (action.payload.students) state.students = action.payload.students;
        if (action.payload.referents) state.referents = action.payload.referents;
        if (action.payload.password) state.password = action.payload.password;
        if (action.payload.isLoggedIn) state.isLoggedIn = action.payload.isLoggedIn;
      },
      updateUserStudents: (state, action:PayloadAction<Partial<Student>>) => {
        state.students = state.students.map((student) => (
          student.id === action.payload.id ? { ...student, ...action.payload } : student));
      },
      updateUserReferents: (state, action:PayloadAction<Partial<Referent>>) => {
        state.referents = state.referents.map((referent) => (
          referent.id === action.payload.id ? { ...referent, ...action.payload } : referent));
      },
    },
  },
);

export const { updateUser, updateUserStudents, updateUserReferents } = userSlice.actions;

export const selectUser = (state:RootState) => state.user;

export default userSlice.reducer;
