import React from 'react';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as SkiFamilyLogo } from '../../assets/icons/skifamily-logo.svg';
import { ReactComponent as EsiLogo } from '../../assets/images/logo-ESI.svg';

import './style.scss';

export default (): JSX.Element => (
  <div className="header-container">
    <a className="header-button" href="/reservation/station">
      <ArrowBack title="back" />
      <span>début formulaire</span>
    </a>

    <div className="header-skifamily-logo">
      <a href={process.env.REACT_APP_API_URL} target="_blank" rel="noreferrer">
        <SkiFamilyLogo title="logo" height={50} className="skifamily-logo" />
      </a>
    </div>
    <div className="header-logo">
      <EsiLogo title="logo ESI" />
      <span>Ski Family est une école du réseau ESI.</span>
    </div>
  </div>
);
