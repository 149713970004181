import moment from 'moment/moment';
import { LessonDay } from '../types/lessonDay';
import {
  AFTERNOON, ALLDAY, MORNING, NOON, TIME_FORMAT,
} from '../constants/constants';

export const getTimeSlot = (lessonDay:LessonDay) => {
  const startMoment = moment(lessonDay.start, TIME_FORMAT);
  const endMoment = moment(lessonDay.end, TIME_FORMAT);
  if (startMoment.hour() > 6 && startMoment.hour() < 12 && endMoment.hour() < 14) {
    return MORNING;
  }
  if (startMoment.hour() >= 12 && endMoment.hour() <= 14) {
    return NOON;
  }
  if (startMoment.hour() >= 14) {
    return AFTERNOON;
  }
  return ALLDAY;
};

export const getLessonDayClassLength = (lessonDay:LessonDay):number => moment(lessonDay.end, TIME_FORMAT).diff(moment(lessonDay.start, TIME_FORMAT), 'hours');
