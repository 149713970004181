import React, { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';

import { LessonCalendar, RadioGroup } from '../..';
import { classLengthOptions } from '../../../constants/tunnelOptions';
import SellerInstructorLine from '../../instructorLine/instructorLine';
import './privateLessonBlock.scss';
import { Lesson } from '../../../types/lesson';
import { Instructor } from '../../../types/instructor';
import { LessonDay } from '../../../types/lessonDay';
import { Product } from '../../../types/product';
import {
  getUniqueInstructorListFromProducts,
  isProductOfClassLength,
} from '../../../helpers/products';
import { useAppDispatch } from '../../../hooks';
import { updateLesson } from '../../../services/lessons/lessonsSlice';

interface Props {
  lesson: Lesson;
  date: Moment | null;
  products:Array<Product>;
}

export default ({
  lesson, date, products,
}: Props): JSX.Element => {
  const [availableLessonDays, setAvailableLessonDays] = useState<Array<LessonDay>>(products.flatMap(
    (product) => product.days,
  ));
  const [availableInstructors, setAvailableInstructors] = useState<Array<Instructor>>(
    getUniqueInstructorListFromProducts(products),
  );

  const dispatch = useAppDispatch();

  const onClassLengthUpdate = useCallback((value: number): void => {
    // finding all possibilties for given classlength
    const possibleProducts = products.filter((product) => isProductOfClassLength(product, value));
    if (possibleProducts) {
      // initializing timeslots for each days
      setAvailableLessonDays(possibleProducts.flatMap((product) => product.days));
      // initializing instructors
      setAvailableInstructors(getUniqueInstructorListFromProducts(possibleProducts));
      // initializing timeslots
      dispatch(updateLesson({
        id: lesson.id,
        classLength: value,
        lessonDays: [],
      }));
    } else {
      dispatch(updateLesson({
        id: lesson.id,
        classLength: value,
      }));
    }
  }, [setAvailableLessonDays, products, date]);

  const onUnselectLessonDay = useCallback((lessonDay: LessonDay): void => {
    // removing selected timeslot, I assume that there is only one
    const newLessonDays = lesson.lessonDays.filter((t) => (t !== lessonDay));
    // retrieve possible products for currently selected class length
    const possibleProducts = products.filter(
      (product) => isProductOfClassLength(product, lesson.classLength),
    );
    // if any products are available update available lessons days and Instructors
    if (possibleProducts) {
      setAvailableLessonDays(possibleProducts.flatMap((product) => product.days));
      setAvailableInstructors(getUniqueInstructorListFromProducts(possibleProducts));
    }
    dispatch(updateLesson({ id: lesson.id, lessonDays: newLessonDays }));
  }, [lesson, setAvailableLessonDays, products, setAvailableInstructors, date]);

  const onSelectLessonDay = useCallback((lessonDay:LessonDay): void => {
    if (lesson.nbLessons > lesson.lessonDays.length) {
      // add a lesson day to the ones already selected
      const selectedLessonDays = [...lesson.lessonDays, lessonDay];
      // updating instructors according to new selected timeslots
      const filteredProducts = products.filter((product) => product.days.includes(lessonDay));
      setAvailableInstructors(getUniqueInstructorListFromProducts(filteredProducts));
      // updating displayed timeslots according to new instructor list
      setAvailableLessonDays(filteredProducts.flatMap((product) => product.days));
      dispatch(updateLesson({ id: lesson.id, lessonDays: selectedLessonDays }));
    }
  }, [availableLessonDays, setAvailableLessonDays, availableInstructors, setAvailableInstructors,
    lesson.nbLessons, lesson.lessonDays]);

  const displayInstructorLine = () => {
    if (lesson.classLength !== 0 && availableInstructors.length) {
      return <SellerInstructorLine instructors={availableInstructors} />;
    }
    return 'Choisissez parmis les créneaux disponibles';
  };

  return (
    <>
      <div className="seller-private-lesson-block-line">
        <div className="seller-private-lesson-block-description">
          Durées :
        </div>
        <div className="seller-private-lesson-block-radio">
          <RadioGroup
            selected={lesson.classLength}
            options={classLengthOptions}
            onClick={(value): void => {
              if (typeof value === 'number') onClassLengthUpdate(value);
              if (typeof value === 'string')onClassLengthUpdate(moment.duration(value).hours());
            }}
            scrollOnMobile
          />
        </div>
      </div>
      <div className="seller-private-lesson-block-line">
        {!!lesson.classLength && (
        <>
          {displayInstructorLine()}
          <LessonCalendar
            nbLessons={lesson.nbLessons}
            lessonDays={availableLessonDays}
            selected={lesson.lessonDays}
            onSelect={(lessonDay:LessonDay): void => onSelectLessonDay(lessonDay)}
            onUnselect={(lessonDay:LessonDay): void => onUnselectLessonDay(lessonDay)}
          />
        </>
        )}
      </div>
    </>
  );
};
