import React from 'react';
import { useAppDispatch } from '../../../hooks';
import { Dropdown } from '../..';
import './style.scss';
import { ageDropdownOptions } from '../../../constants/tunnelOptions';
import { updateLesson } from '../../../services/lessons/lessonsSlice';
import { Lesson } from '../../../types/lesson';

interface Props {
  lesson: Lesson;
  showError: boolean;
}

export default ({ lesson, showError }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const onAgeChange = (value: string): void => {
    dispatch(updateLesson({ ...lesson, ...{ age: value } }));
  };

  return (
    <div className="lesson-line">
      age :
      <Dropdown
        placeholder="tranche d'age"
        value={lesson.age}
        options={ageDropdownOptions}
        onChange={onAgeChange}
        error={showError && !lesson.age}
      />
    </div>
  );
};
