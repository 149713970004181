import React from 'react';

import '../lessonDetails/style.scss';
import { RadioGroup } from '../..';
import SellerPublicLessonBlock from '../publicLessonBlock/publicLessonBlock';
import { lessonDayTimeOptions, startingDayOptions } from '../../../constants/tunnelOptions';
import { useAppSelector } from '../../../hooks';
import { selectLessons } from '../../../services/lessons/lessonsSlice';
import { MORNING, SUNDAY } from '../../../constants/constants';

interface Props {
  index: number;
  onChange: (payload: object) => void;
}

export default ({ index, onChange }: Props): JSX.Element => {
  const lessons = useAppSelector(selectLessons);

  return (
    <>
      <div className="lesson-details-line">
        <div className="lesson-details-line">
          <div className="lesson-details-group">
            <RadioGroup
              selected={lessons[index].timeslot}
              options={lessonDayTimeOptions}
              onClick={(value): void => onChange({ timeslot: value })}
            />
            <div className="lesson-details-information">
              {lessons[index].timeslot === MORNING ? '(9h15 - 12h)' : '(14h35 - 17h)'}
            </div>
          </div>
          <div className="lesson-details-group">
            <RadioGroup
              selected={lessons[index].startDay}
              options={startingDayOptions}
              onClick={(value): void => onChange({ startsOnSunday: value })}
            />
            <div className="lesson-details-information">
              {lessons[index].startDay === SUNDAY ? '(6j -> vendredi)' : '(5j -> vendredi)'}
            </div>
          </div>
        </div>
      </div>
      <SellerPublicLessonBlock
        lesson={lessons[index]}
        onUpdate={(value: {}): void => onChange(value)}
      />
    </>
  );
};
